import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store/authSlice';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Fire Action for Remove all Items from localStorage and redirect to login page
    dispatch(authActions.logout());
    navigate('/login');
  }, [dispatch, navigate]);

  return null;
};

export default Logout;