import React, { useState, useEffect } from 'react';
import {
    Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Badge, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import {
    FaPlus, FaEdit, FaTrash, FaMapMarkerAlt, FaAngleDoubleLeft, FaAngleLeft,
    FaAngleRight, FaAngleDoubleRight,
    FaFileExcel
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { prefectureActions } from '../../../store/prefectureSlice';
import { regionActions } from '../../../store/regionSlice';
import { districtActions } from '../../../store/districtSlice';
import * as XLSX from 'xlsx';

const Prefectures = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Main prefecture state
    const {
        prefectures,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
    } = useSelector((state) => state.prefectures);
    const {
        regions,
        isFetching: isFetchingRegions,
        totalPages: totalPagesRegions,
        totalElements: totalElementsRegions,
        pageNumber: pageNumberRegions,
        pageSize: pageSizeRegions,
        error: errorRegions,
    } = useSelector((state) => state.regions);

    const {
        districts,
        isFetching: isFetchingDistricts,
        totalPages: totalPagesDistricts,
        totalElements: totalElementsDistricts,
        pageNumber: pageNumberDistricts,
        pageSize: pageSizeDistricts,
        error: errorDistricts,
    } = useSelector((state) => state.districts);

    // Component state
    const [modal, setModal] = useState(false);
    const [regionModal, setRegionModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageRegion, setCurrentPageRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(null);
    const [currentPageDistrict, setCurrentPageDistrict] = useState(pageNumberDistricts); // Track current page
    const [districtModal, setDistrictModal] = useState(false);
    const [formData, setFormData] = useState({
        codePrefecture: '',
        nomPrefecture: '',
        codeRegion: '',
        nomRegion: '',
        active: 'Y',
        coordonneesGps: '',
        codeDistrict: '',
        nomDistrict: '',
    });

    useEffect(() => {
        dispatch(districtActions.search({ page: currentPageDistrict, size: pageSizeDistricts }));
    }, [currentPageDistrict]);

    // Add page change handler
    const handleRegionPageChange = (newPage) => {
        setCurrentPageRegion(newPage);
        dispatch(regionActions.search({ page: newPage, size: pageSizeRegions }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Load initial data
    useEffect(() => {
        dispatch(prefectureActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage]);

    const handleEdit = (prefecture) => {
        setSelectedPrefecture(prefecture);
        setFormData({
            codePrefecture: prefecture.codePrefecture,
            nomPrefecture: prefecture.nomPrefecture,
            codeRegion: prefecture.codeRegion,
            nomRegion: prefecture.nomRegion,
            codeDistrict: prefecture.codeDistrict,
            nomDistrict: prefecture.nomDistrict,
            active: prefecture.active,
            coordonneesGps: prefecture.coordonneesGps
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('prefecture.delete.confirmTitle'),
            text: t('prefecture.delete.confirmText'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: t('prefecture.delete.confirm'),
            cancelButtonText: t('prefecture.delete.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(prefectureActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('prefecture.delete.successTitle'),
                            text: t('prefecture.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(prefectureActions.search({ page: currentPage, size: pageSize }));
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('prefecture.delete.errorTitle'),
                            text: error.message || t('prefecture.delete.errorText'),
                            icon: 'error'
                        });
                    }
                }));
            }
        });
    };

    const toggleDistrictModal = () => {
        setDistrictModal(!districtModal);
        if (!districtModal) {
            dispatch(districtActions.search({ page: 0, size: 5 }));
        }
    };

    const handleDistrictSelect = (district) => {
        setFormData({
            ...formData,
            codeDistrict: district.codeDistrict,
            nomDistrict: district.nomDistrict,
            // Reset dependent fields
            codeRegion: '',
            nomRegion: '',
        });
        toggleDistrictModal();
        if (district.codeDistrict) {
            dispatch(regionActions.search({ page: 0, size: pageSizeRegions, codeDistrict: district.codeDistrict }));
        }
    };

    const handleDistrictPageChange = (newPage) => {
        setCurrentPageDistrict(newPage);
    };

    // Modal handlers
    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                codePrefecture: '',
                nomPrefecture: '',
                codeRegion: '',
                nomRegion: '',
                active: 'Y',
                coordonneesGps: '',
                codeDistrict: '',
                nomDistrict: '',
            });
            setSelectedPrefecture(null);
        }
    };

    const toggleRegionModal = () => {
        setRegionModal(!regionModal);
    };

    // CRUD handlers
    const handleRegionSelect = (region) => {
        setFormData({
            ...formData,
            codeRegion: region.codeRegion,
            nomRegion: region.nomRegion
        });
        toggleRegionModal();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedPrefecture?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedPrefecture ? 'prefecture.update.successTitle' : 'prefecture.create.successTitle'),
                    text: t(selectedPrefecture ? 'prefecture.update.successText' : 'prefecture.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(prefectureActions.search({ page: currentPage, size: pageSize }));
            }
        };
        dispatch(selectedPrefecture ?
            prefectureActions.update(payload) :
            prefectureActions.create(payload)
        );
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('prefecture.export.loading'),
            text: t('prefecture.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(prefecture => ({
                    'Code Préfecture': prefecture.codePrefecture,
                    'Nom Préfecture': prefecture.nomPrefecture,
                    'Code Région': prefecture.codeRegion,
                    'Nom Région': prefecture.nomRegion,
                    'Code District': prefecture.codeDistrict,
                    'Nom District': prefecture.nomDistrict,
                    'Coordonnées GPS': prefecture.coordonneesGps || '',
                    'Statut': prefecture.active === 'Y' ? 'Actif' : 'Inactif'
                }));
    
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Prefectures');
    
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
    
                const fileName = `prefectures_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
    
                Swal.fire({
                    title: t('prefecture.export.successTitle'),
                    text: t('prefecture.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('prefecture.export.errorTitle'),
                    text: t('prefecture.export.errorText'),
                    icon: 'error'
                });
            }
        };
    
        dispatch(prefectureActions.findAll(payload));
    };

    return (
        <Card>
            <CardBody>

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('prefecture.title')}</h4>
                        <p className="text-muted small mb-0">{t('prefecture.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('prefecture.new')}
                        </Button>
                    </div>
                </div>

                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>{t('prefecture.code')}</th>
                            <th>{t('prefecture.name')}</th>
                            <th>{t('prefecture.region')}</th>
                            <th>{t('prefecture.coordinates')}</th>
                            <th>{t('prefecture.status')}</th>
                            <th>{t('prefecture.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prefectures.map(prefecture => (
                            <tr key={prefecture.id}>
                                <td>{prefecture.codePrefecture}</td>
                                <td>{prefecture.nomPrefecture}</td>
                                <td>{prefecture.nomRegion}</td>
                                <td>{prefecture.coordonneesGps}</td>
                                <td>
                                    <Badge color={prefecture.active === 'Y' ? 'success' : 'secondary'}>
                                        {prefecture.active === 'Y' ? t('prefecture.active') : t('prefecture.inactive')}
                                    </Badge>
                                </td>
                                <td>
                                    <Button color="info" size="sm" className="me-2"
                                        onClick={() => handleEdit(prefecture)}>
                                        <FaEdit />
                                    </Button>
                                    <Button color="danger" size="sm"
                                        onClick={() => handleDelete(prefecture.id)}>
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="text-muted">
                        {t('prefecture.pagination.showing')} {pageNumber * pageSize + 1} {t('prefecture.pagination.to')}{' '}
                        {Math.min((pageNumber + 1) * pageSize, totalElements)} {t('prefecture.pagination.of')} {totalElements}{' '}
                        {t('prefecture.pagination.entries')}
                    </div>

                    <Pagination>
                        <PaginationItem disabled={pageNumber === 0}>
                            <PaginationLink first onClick={() => handlePageChange(0)}>
                                <FaAngleDoubleLeft size={12} />
                            </PaginationLink>
                        </PaginationItem>

                        <PaginationItem disabled={pageNumber === 0}>
                            <PaginationLink previous onClick={() => handlePageChange(pageNumber - 1)}>
                                <FaAngleLeft size={12} />
                            </PaginationLink>
                        </PaginationItem>

                        {/* {[...Array(totalPages)].map((_, index) => (
                            <PaginationItem active={pageNumber === index} key={index}>
                                <PaginationLink onClick={() => handlePageChange(index)}>
                                    {index + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))} */}

                        <PaginationItem disabled={pageNumber === totalPages - 1}>
                            <PaginationLink next onClick={() => handlePageChange(pageNumber + 1)}>
                                <FaAngleRight size={12} />
                            </PaginationLink>
                        </PaginationItem>

                        <PaginationItem disabled={pageNumber === totalPages - 1}>
                            <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                <FaAngleDoubleRight size={12} />
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </div>
                {/* Prefecture Form Modal */}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        {selectedPrefecture ? t('prefecture.edit') : t('prefecture.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label>{t('sousPrefecture.district')}</Label>
                                <div className="d-flex gap-2">
                                    <Input
                                        value={formData.nomDistrict}
                                        readOnly
                                        placeholder={t('sousPrefecture.selectDistrict')}
                                    />
                                    <Button color="secondary" onClick={toggleDistrictModal}>
                                        <FaMapMarkerAlt />
                                    </Button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('prefecture.region')}</Label>
                                <div className="d-flex gap-2">
                                    <Input
                                        value={formData.nomRegion}
                                        readOnly
                                        placeholder={t('prefecture.selectRegion')}
                                    />
                                    <Button color="secondary" onClick={toggleRegionModal}>
                                        <FaMapMarkerAlt />
                                    </Button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="codePrefecture">{t('prefecture.code')}</Label>
                                <Input
                                    id="codePrefecture"
                                    name="codePrefecture"
                                    value={formData.codePrefecture}
                                    onChange={e => setFormData({ ...formData, codePrefecture: e.target.value })}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomPrefecture">{t('prefecture.name')}</Label>
                                <Input
                                    id="nomPrefecture"
                                    name="nomPrefecture"
                                    value={formData.nomPrefecture}
                                    onChange={e => setFormData({ ...formData, nomPrefecture: e.target.value })}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="coordonneesGps">{t('prefecture.coordinates')}</Label>
                                <Input
                                    id="coordonneesGps"
                                    name="coordonneesGps"
                                    value={formData.coordonneesGps}
                                    onChange={e => setFormData({ ...formData, coordonneesGps: e.target.value })}
                                    placeholder="Lat, Long"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="active">{t('prefecture.status')}</Label>
                                <Input
                                    type="select"
                                    id="active"
                                    name="active"
                                    value={formData.active}
                                    onChange={e => setFormData({ ...formData, active: e.target.value })}
                                >
                                    <option value="Y">{t('prefecture.active')}</option>
                                    <option value="N">{t('prefecture.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('prefecture.cancel')}</Button>
                            <Button color="primary" type="submit">{t('prefecture.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>

                <Modal isOpen={districtModal} toggle={toggleDistrictModal} size="lg">
                    <ModalHeader toggle={toggleDistrictModal}>
                        {t('region.selectDistrict')}
                    </ModalHeader>
                    <ModalBody>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t('district.code')}</th>
                                    <th>{t('district.name')}</th>
                                    <th>{t('district.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {districts.map(district => (
                                    <tr key={district.id}>
                                        <td>{district.codeDistrict}</td>
                                        <td>{district.nomDistrict}</td>
                                        <td>
                                            <Button color="primary" size="sm" onClick={() => handleDistrictSelect(district)}>
                                                {t('region.select')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            <Pagination>
                                <PaginationItem disabled={currentPageDistrict === 0}>
                                    <PaginationLink first onClick={() => handleDistrictPageChange(0)}>
                                        <FaAngleDoubleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPageDistrict === 0}>
                                    <PaginationLink previous onClick={() => handleDistrictPageChange(currentPageDistrict - 1)}>
                                        <FaAngleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                {[...Array(totalPagesDistricts)].map((_, index) => (
                                    <PaginationItem active={currentPageDistrict === index} key={index}>
                                        <PaginationLink onClick={() => handleDistrictPageChange(index)}>
                                            {index + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPageDistrict === totalPagesDistricts - 1}>
                                    <PaginationLink next onClick={() => handleDistrictPageChange(currentPageDistrict + 1)}>
                                        <FaAngleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={currentPageDistrict === totalPagesDistricts - 1}>
                                    <PaginationLink last onClick={() => handleDistrictPageChange(totalPagesDistricts - 1)}>
                                        <FaAngleDoubleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Region Selector Modal */}
                <Modal isOpen={regionModal} toggle={toggleRegionModal} size="lg">
                    <ModalHeader toggle={toggleRegionModal}>
                        {t('prefecture.selectRegion')}
                    </ModalHeader>
                    <ModalBody>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t('region.code')}</th>
                                    <th>{t('region.name')}</th>
                                    <th>{t('region.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {regions.map(region => (
                                    <tr key={region.id}>
                                        <td>{region.codeRegion}</td>
                                        <td>{region.nomRegion}</td>
                                        <td>
                                            <Button color="primary" size="sm"
                                                onClick={() => handleRegionSelect(region)}>
                                                {t('prefecture.select')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            <Pagination>
                                <PaginationItem disabled={currentPageRegion === 0}>
                                    <PaginationLink first onClick={() => handleRegionPageChange(0)}>
                                        <FaAngleDoubleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>

                                <PaginationItem disabled={currentPageRegion === 0}>
                                    <PaginationLink previous onClick={() => handleRegionPageChange(currentPageRegion - 1)}>
                                        <FaAngleLeft size={12} />
                                    </PaginationLink>
                                </PaginationItem>

                                {[...Array(totalPagesRegions)].map((_, index) => (
                                    <PaginationItem active={currentPageRegion === index} key={index}>
                                        <PaginationLink onClick={() => handleRegionPageChange(index)}>
                                            {index + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}

                                <PaginationItem disabled={currentPageRegion === totalPagesRegions - 1}>
                                    <PaginationLink next onClick={() => handleRegionPageChange(currentPageRegion + 1)}>
                                        <FaAngleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>

                                <PaginationItem disabled={currentPageRegion === totalPagesRegions - 1}>
                                    <PaginationLink last onClick={() => handleRegionPageChange(totalPagesRegions - 1)}>
                                        <FaAngleDoubleRight size={12} />
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </ModalBody>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Prefectures;