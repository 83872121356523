import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Alert, Label, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import authModule, { authActions } from "../../store/authSlice";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

const Login = () => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state selectors
  const loginError = useSelector((state) => state.auth.error);
  const logoutMessage = useSelector((state) => state.auth.logoutMessage);
  const isFetching = useSelector((state) => state.auth.isFetching);

  useEffect(() => {
    dispatch(authModule.actions.loginFailure("")); // Clear previous errors
    document.body.classList.add("auth-body-bg");

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, [dispatch]);

  const handleSubmit = (event, values) => {
    dispatch(authActions.loginRequest({ ...values, navigate }));
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logodark}
                                alt=""
                                height="100"
                                className="logo-dark mx-auto"
                              />
                              {/* <img
                                src={logolight}
                                alt=""
                                height="60"
                                className="auth-logo logo-light mx-auto"
                              /> */}
                            </Link>
                          </div>
                          <h4 className="font-size-18 mt-4">Bienvenue !</h4>
                          <p className="text-muted">
                            Connectez-vous pour continuer vers AgriTracking.
                          </p>
                        </div>
                        {logoutMessage && (
                          <Alert color="info">{logoutMessage}</Alert>
                        )}
                        {loginError && (
                          <Alert color="danger">{loginError}</Alert>
                        )}
                        <div className="p-2 mt-5">
                          {isFetching ? (
                            <div className="text-center">
                              <Spinner color="primary" />
                              <p>Connexion en cours...</p>
                            </div>
                          ) : (
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  name="username"
                                  value={username}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  validate={{ required: true }}
                                  placeholder="Enter username"
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                              </div>

                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={password}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                  validate={{ required: true }}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Se connecter
                                </Button>
                              </div>

                              <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock me-1"></i> Mot de
                                  passe oublié ?
                                </Link>
                              </div>
                            </AvForm>
                          )}
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            © {new Date().getFullYear()} AgriTracking.
                            Développé par{" "}
                            <a
                              href="https://ideracloud.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              IDERACLOUD
                            </a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
