import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { faitiereActions } from '../../../store/faitiereSlice';
import * as XLSX from 'xlsx';

const Faitieres = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        faitieres,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.faitieres);
    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedFaitiere, setSelectedFaitiere] = useState(null);
    const [formData, setFormData] = useState({
        codeFaitiere: '',
        nomFaitiere: '',
        dateCreation: '',
        dateDebutPartenariat: '',
        nomPrenomsRepresentant: '',
        contactRepresentant: '',
        email: '',
        nombreMembreHomme: '',
        nombreMembreFemme: '',
        siegeSocial: '',
        descriptions: ''
    });

    useEffect(() => {
        dispatch(faitiereActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage, dispatch]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                codeFaitiere: '',
                nomFaitiere: '',
                dateCreation: '',
                dateDebutPartenariat: '',
                nomPrenomsRepresentant: '',
                contactRepresentant: '',
                email: '',
                nombreMembreHomme: '',
                nombreMembreFemme: '',
                siegeSocial: '',
                descriptions: ''
            });
            setSelectedFaitiere(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedFaitiere?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedFaitiere ? 'faitiere.update.successTitle' : 'faitiere.create.successTitle'),
                    text: t(selectedFaitiere ? 'faitiere.update.successText' : 'faitiere.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(faitiereActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedFaitiere ? 'faitiere.update.errorTitle' : 'faitiere.create.errorTitle'),
                    text: error.message || t(selectedFaitiere ? 'faitiere.update.errorText' : 'faitiere.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedFaitiere ?
            faitiereActions.update(payload) :
            faitiereActions.create(payload)
        );
    };

    const handleEdit = (faitiere) => {
        setSelectedFaitiere(faitiere);
        setFormData({
            codeFaitiere: faitiere.codeFaitiere,
            nomFaitiere: faitiere.nomFaitiere,
            dateCreation: faitiere.dateCreation,
            dateDebutPartenariat: faitiere.dateDebutPartenariat,
            nomPrenomsRepresentant: faitiere.nomPrenomsRepresentant,
            contactRepresentant: faitiere.contactRepresentant,
            email: faitiere.email,
            nombreMembreHomme: faitiere.nombreMembreHomme,
            nombreMembreFemme: faitiere.nombreMembreFemme,
            siegeSocial: faitiere.siegeSocial,
            descriptions: faitiere.descriptions
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('faitiere.delete.confirmTitle'),
            text: t('faitiere.delete.confirmText'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('faitiere.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(faitiereActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('faitiere.delete.successTitle'),
                            text: t('faitiere.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(faitiereActions.search({ page: 0, size: pageSize }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('faitiere.delete.errorTitle'),
                            text: error.message || t('faitiere.delete.errorText'),
                            icon: 'error'
                        });
                    }

                }));
            }
        });
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('faitiere.export.loading'),
            text: t('faitiere.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(faitiere => ({
                    'Code': faitiere.codeFaitiere,
                    'Nom': faitiere.nomFaitiere,
                    'Date Création': faitiere.dateCreation ? new Date(faitiere.dateCreation).toLocaleDateString() : '',
                    'Date Début Partenariat': faitiere.dateDebutPartenariat ? new Date(faitiere.dateDebutPartenariat).toLocaleDateString() : '',
                    'Représentant': faitiere.nomPrenomsRepresentant || '',
                    'Contact': faitiere.contactRepresentant || '',
                    'Email': faitiere.email || '',
                    'Membres Hommes': faitiere.nombreMembreHomme || 0,
                    'Membres Femmes': faitiere.nombreMembreFemme || 0,
                    'Total Membres': (faitiere.nombreMembreHomme || 0) + (faitiere.nombreMembreFemme || 0),
                    'Siège Social': faitiere.siegeSocial || '',
                    'Description': faitiere.descriptions || ''
                }));
    
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Faitières');
    
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
    
                const fileName = `faitieres_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
    
                Swal.fire({
                    title: t('faitiere.export.successTitle'),
                    text: t('faitiere.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('faitiere.export.errorTitle'),
                    text: t('faitiere.export.errorText'),
                    icon: 'error'
                });
            }
        };
    
        dispatch(faitiereActions.findAll(payload));
    };

    return (
        <Card>
            <CardBody>

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('faitiere.title')}</h4>
                        <p className="text-muted small mb-0">{t('faitiere.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('faitiere.new')}
                        </Button>
                    </div>
                </div>

                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('faitiere.code')}</th>
                                    <th>{t('faitiere.name')}</th>
                                    <th>{t('faitiere.creationDate.label')}</th>
                                    <th>{t('faitiere.partnership.startDate')}</th>
                                    <th>{t('faitiere.representative.fullName')}</th>
                                    <th>{t('faitiere.contact')}</th>
                                    <th>{t('faitiere.members.male')}</th>
                                    <th>{t('faitiere.members.female')}</th>
                                    <th>{t('faitiere.headquarters')}</th>
                                    <th>{t('faitiere.description')}</th>
                                    <th>{t('faitiere.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faitieres && faitieres.map(zone => (
                                    <tr key={zone.id}>
                                        <td>{zone.codeFaitiere}</td>
                                        <td>{zone.nomFaitiere}</td>
                                        <td>{zone.dateDebutPartenariat}</td>
                                        <td>{zone.nomPrenomsRepresentant}</td>
                                        <td>
                                            <div>{zone.contactRepresentant}</div>
                                            <small>{zone.email}</small>
                                        </td>
                                        <td>{zone.nombreMembreHomme}</td>
                                        <td>{zone.nombreMembreFemme}</td>
                                        <td>{zone.siegeSocial}</td>
                                        <td>{zone.descriptions}</td>
                                        <td>
                                            <Button color="info" size="sm" className="me-2"
                                                onClick={() => handleEdit(zone)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm"
                                                onClick={() => handleDelete(zone.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSize + 1,
                                end: Math.min((currentPage + 1) * pageSize, totalElements),
                                total: totalElements,
                                currentPage: currentPage,
                                totalPages: totalPages
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {selectedFaitiere ? t('faitiere.edit') : t('faitiere.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormGroup>
                                        <Label for="codeFaitiere">{t('faitiere.code')}</Label>
                                        <Input
                                            id="codeFaitiere"
                                            name="codeFaitiere"
                                            value={formData.codeFaitiere}
                                            onChange={e => setFormData({ ...formData, codeFaitiere: e.target.value })}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="nomFaitiere">{t('faitiere.name')}</Label>
                                        <Input
                                            id="nomFaitiere"
                                            name="nomFaitiere"
                                            value={formData.nomFaitiere}
                                            onChange={e => setFormData({ ...formData, nomFaitiere: e.target.value })}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="dateCreation">{t('faitiere.creationDate.label')}</Label>
                                        <Input
                                            id="dateCreation"
                                            name="dateCreation"
                                            type="date"
                                            value={formData.dateCreation}
                                            onChange={e => setFormData({ ...formData, dateCreation: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="dateDebutPartenariat">{t('faitiere.partnership.startDate')}</Label>
                                        <Input
                                            id="dateDebutPartenariat"
                                            name="dateDebutPartenariat"
                                            type="date"
                                            value={formData.dateDebutPartenariat}
                                            onChange={e => setFormData({ ...formData, dateDebutPartenariat: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="nomPrenomsRepresentant">{t('faitiere.representative.fullName')}</Label>
                                        <Input
                                            id="nomPrenomsRepresentant"
                                            name="nomPrenomsRepresentant"
                                            value={formData.nomPrenomsRepresentant}
                                            onChange={e => setFormData({ ...formData, nomPrenomsRepresentant: e.target.value })}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <Label for="contactRepresentant">{t('faitiere.contact')}</Label>
                                        <Input
                                            id="contactRepresentant"
                                            name="contactRepresentant"
                                            value={formData.contactRepresentant}
                                            onChange={e => setFormData({ ...formData, contactRepresentant: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">{t('faitiere.email')}</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="nombreMembreHomme">{t('faitiere.members.male')}</Label>
                                        <Input
                                            id="nombreMembreHomme"
                                            name="nombreMembreHomme"
                                            type="number"
                                            value={formData.nombreMembreHomme}
                                            onChange={e => setFormData({ ...formData, nombreMembreHomme: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="nombreMembreFemme">{t('faitiere.members.female')}</Label>
                                        <Input
                                            id="nombreMembreFemme"
                                            name="nombreMembreFemme"
                                            type="number"
                                            value={formData.nombreMembreFemme}
                                            onChange={e => setFormData({ ...formData, nombreMembreFemme: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="siegeSocial">{t('faitiere.headquarters')}</Label>
                                        <Input
                                            id="siegeSocial"
                                            name="siegeSocial"
                                            value={formData.siegeSocial}
                                            onChange={e => setFormData({ ...formData, siegeSocial: e.target.value })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="descriptions">{t('faitiere.description')}</Label>
                                        <Input
                                            id="descriptions"
                                            name="descriptions"
                                            type="textarea"
                                            value={formData.descriptions}
                                            onChange={e => setFormData({ ...formData, descriptions: e.target.value })}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('faitiere.cancel')}</Button>
                            <Button color="primary" type="submit">{t('faitiere.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Faitieres;