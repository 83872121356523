import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard and new pages
import Dashboard from "../pages/Dashboard";
import GestionProducteurs from "../pages/GestionProducteurs";
import GestionParcelles from "../pages/GestionParcelles";
import ActivitesParcelles from "../pages/ActivitesParcelles";
import VisiteAgricole from "../pages/VisiteAgricole";
import AchatsStocks from "../pages/AchatsStocks";
import GestionDonnees from "../pages/GestionDonnees";
import GestionUtilisateurs from "../pages/GestionUtilisateurs";

// Error page
import ErrorPage from "../pages/Error";
import UserForm from "../pages/GestionUtilisateurs/UserForm";
import ProducteurForm from "../pages/GestionProducteurs/ProducteurForm";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/gestion-producteurs", component: <GestionProducteurs /> },
  { path: "/gestion-producteurs/form/:id?", component: <ProducteurForm /> },
  { path: "/gestion-parcelles", component: <GestionParcelles /> },
  { path: "/activites-parcelles", component: <ActivitesParcelles /> },
  { path: "/visite-agricole", component: <VisiteAgricole /> },
  { path: "/achats-stocks", component: <AchatsStocks /> },
  { path: "/gestion-donnees", component: <GestionDonnees /> },
  { path: "/gestion-utilisateurs", component: <GestionUtilisateurs /> },
  { path: "/gestion-utilisateurs/form/:id?", component: <UserForm /> },

  // Redirect to dashboard
  { path: "/", exact: true, component: <Navigate to="/login" /> },

  // Catch-all route for undefined paths
  { path: "*", component: <Navigate to="/error?status=404&message=Page%20Not%20Found" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  // Error page route
  { path: "/error", component: <ErrorPage /> },

  // Catch-all route for undefined paths
  { path: "*", component: <Navigate to="/error?status=404&message=Page%20Not%20Found" /> },
];

export { authProtectedRoutes, publicRoutes };
