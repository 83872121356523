import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { districtActions } from '../../../store/districtSlice';
import * as XLSX from 'xlsx';

const Districts = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        districts,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.districts);
    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [formData, setFormData] = useState({
        codeDistrict: '',
        nomDistrict: '',
        active: 'Y'
    });

    useEffect(() => {
        dispatch(districtActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({ codeDistrict: '', nomDistrict: '', active: 'Y' });
            setSelectedDistrict(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedDistrict?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedDistrict ? 'culture.update.successTitle' : 'culture.create.successTitle'),
                    text: t(selectedDistrict ? 'culture.update.successText' : 'culture.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(districtActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedDistrict ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
                    text: error.message || t(selectedDistrict ? 'culture.update.errorText' : 'culture.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedDistrict ?
            districtActions.update(payload) :
            districtActions.create(payload)
        );
    };

    const handleEdit = (district) => {
        setSelectedDistrict(district);
        setFormData({
            codeDistrict: district.codeDistrict,
            nomDistrict: district.nomDistrict,
            active: district.active
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('culture.delete.title'),
            text: t('culture.delete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('culture.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(districtActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('culture.delete.successTitle'),
                            text: t('culture.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(districtActions.search({ page: 0, size: pageSize }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('culture.delete.errorTitle'),
                            text: error.message || t('culture.delete.errorText'),
                            icon: 'error'
                        });
                    }

                }));
            }
        });
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('district.export.loading'),
            text: t('district.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(district => ({
                    'Code': district.codeDistrict,
                    'Nom': district.nomDistrict,
                    'Statut': district.active === 'Y' ? 'Actif' : 'Inactif',
                    'Coordonnées GPS': district.coordonneesGps || '',
                    'Contact': district.contact || '',
                    'Email': district.email || '',
                    'Superviseur': district.nomSuperviseurDistrict || '',
                    'Matricule Superviseur': district.matriculeSuperviseurDistrict || ''
                }));

                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Districts');

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const fileName = `districts_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();

                Swal.fire({
                    title: t('district.export.successTitle'),
                    text: t('district.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('district.export.errorTitle'),
                    text: t('district.export.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(districtActions.findAll(payload));
    };

    return (
        <Card>
            <CardBody>

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('district.title')}</h4>
                        <p className="text-muted small mb-0">{t('district.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('district.new')}
                        </Button>
                    </div>
                </div>

                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('district.code')}</th>
                                    <th>{t('district.name')}</th>
                                    <th>{t('district.status')}</th>
                                    <th>{t('district.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {districts.map(district => (
                                    <tr key={district.id}>
                                        <td>{district.codeDistrict}</td>
                                        <td>{district.nomDistrict}</td>
                                        <td>
                                            <Badge color={district.active === 'Y' ? "success" : "secondary"}>
                                                {district.active === 'Y' ? t('typePiece.active') : t('typePiece.inactive')}
                                            </Badge>
                                        </td>                                        <td>
                                            <Button color="info" size="sm" className="me-2"
                                                onClick={() => handleEdit(district)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm"
                                                onClick={() => handleDelete(district.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSize + 1,
                                end: Math.min((currentPage + 1) * pageSize, totalElements),
                                total: totalElements,
                                currentPage: currentPage,
                                totalPages: totalPages
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        {selectedDistrict ? t('district.edit') : t('district.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>

                            <FormGroup>
                                <Label for="nomDistrict">{t('district.name')}</Label>
                                <Input
                                    id="nomDistrict"
                                    name="nomDistrict"
                                    value={formData.nomDistrict}
                                    onChange={e => setFormData({ ...formData, nomDistrict: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="active">{t('district.status')}</Label>
                                <Input
                                    type="select"
                                    id="active"
                                    name="active"
                                    value={formData.active}
                                    onChange={e => setFormData({ ...formData, active: e.target.value })}
                                >
                                    <option value="Y">{t('district.active')}</option>
                                    <option value="N">{t('district.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('district.cancel')}</Button>
                            <Button color="primary" type="submit">{t('district.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Districts;