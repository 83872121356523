import React, { Component } from "react";
import { Row, Col, Collapse, Container } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";

// i18n
import { withTranslation } from "react-i18next";

import { connect } from 'react-redux';
import withRouter from "../Common/withRouter";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({});
        }
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.router.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active");
            const parent2 = parent.parentElement;
            parent2.classList.add("active");
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active");
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className="topnav">
                    <Container fluid>
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">

                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/gestion-producteurs">
                                            <i className="ri-user-add-line me-2"></i> {this.props.t('Gestion des producteurs')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/gestion-parcelles">
                                            <i className="ri-map-pin-line me-2"></i> {this.props.t('Gestion des parcelles')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/activites-parcelles">
                                            <i className="ri-plant-line me-2"></i> {this.props.t('Activités des parcelles')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/visite-agricole">
                                            <i className="ri-search-eye-line me-2"></i> {this.props.t('Visite agricole')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/achats-stocks">
                                            <i className="ri-store-2-line me-2"></i> {this.props.t('Achats et stocks')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/gestion-donnees">
                                            <i className="ri-database-2-line me-2"></i> {this.props.t('Données de base')}
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/gestion-utilisateurs">
                                            <i className="ri-user-settings-line me-2"></i> {this.props.t('Gestion des utilisateurs')}
                                        </Link>
                                    </li>

                                </ul>
                            </Collapse>
                        </nav>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { leftSideBarType, leftSideBarTheme } = state.Layout;
    return { leftSideBarType, leftSideBarTheme };
}

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(Navbar)));
