import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Container, Spinner, Input, FormGroup, Label, Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { userActions } from '../../store/userSlice';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FaBox, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const UserList = () => {
  const dispatch = useDispatch();
  const {
    isFetching,
    totalPages,
    users,
    totalElements,
    pageNumber,
    pageSize,
    error
  } = useSelector((state) => state.user);

  const masterData = useSelector((state) => state.masterData.data);
  const { t } = useTranslation("translation");
  const navigate = useNavigate();

  const [page, setPage] = useState(pageNumber); // Track current page
  const criteria = { code: "" };
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Track selected row IDs


  const [searchCode, setSearchCode] = useState('');
  const [enabledFilter, setEnabledFilter] = useState('');

  useEffect(() => {

    let payload = {
      criteria,
      page,
      size: pageSize,
    };
    dispatch(userActions.search(payload));

  }, [page]);



  const handleSearch = () => {
    dispatch(userActions.list({ code: searchCode, enabled: enabledFilter }));
  };

  const handleReset = () => {
    setSearchCode('');
    setEnabledFilter('');
  };

  const getDistrictName = (id) => {
    return masterData?.districts?.find((district) => district.id === id)?.description || 'N/A';
  };

  const getRegionName = (id) => {
    return masterData?.regions?.find((region) => region.id === id)?.description || 'N/A';
  };

  const getZoneName = (id) => {
    return masterData?.zones?.find((zone) => zone.id === id)?.description || 'N/A';
  };

  if (isFetching) {
    return <Spinner color="primary" />;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      text: "Cette action supprimera définitivement cette utilisateur. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id,
          onSuccess: () => {
            dispatch(
              userActions.search({
                criteria,
                page: page,
                size: pageSize,
              })
            );
            Swal.fire({
              title: "Supprimé !",
              text: "L'utilisateur' a été supprimée avec succès.",
              icon: "success",
              confirmButtonText: "OK",
            });
          },
          onError: (error) => {
            Swal.fire({
              title: "Erreur !",
              text:
                error.message ||
                "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log("Error : ", error);
          },
        };

        dispatch(userActions.delete(payload));
        console.log(payload);
      }
    });
  };

  const goEditPage = (id) => {
    navigate(`/gestion-utilisateurs/form/${id}?token=${localStorage.getItem("token")}`);
  };
  const columns = [
    {
      Header: t("CodeUser"),
      accessor: "code",
      disableFilters: true,
    },
    {
      accessor: "createdDate",
      Header: `${t("DateCreation")}`,
      disableFilters: true,
    },
    {
      Header: t("District"),
      disableFilters: true,
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            {getDistrictName(cellProps.districtId)}
          </React.Fragment>
        );
      },
    },
    {
      Header: t("Region"),
      disableFilters: true,
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            {getRegionName(cellProps.regionId)}
          </React.Fragment>
        );
      },
    },
    {
      Header: t("Zone"),
      disableFilters: true,
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            {getZoneName(cellProps.zoneId)}
          </React.Fragment>
        );
      },
    },

    {
      Header: t("NomUser"),
      accessor: "nom",
      disableFilters: true,
    },
    {
      Header: t("Prenom"),
      accessor: "prenom",
      disableFilters: true,
    },

    // {
    //   Header: t("Username"),
    //   accessor: "username",
    //   disableFilters: true,
    // },
    // {
    //   Header: t("Email"),
    //   accessor: "email",
    //   disableFilters: true,
    // },
    // {
    //   Header: t("Tel"),
    //   accessor: "tel",
    //   disableFilters: true,
    // },

    {
      Header: t("Roles"),
      disableFilters: true,
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            {cellProps.roles.join(', ')}
          </React.Fragment>
        );
      },
    },
    {
      Header: t("Etat"),
      accessor: "enabled",
      disableFilters: true,
      Cell: ({ value }) => <div style={statusStyles(value ? 'Actif' : 'Inactif')}>{value ? 'Actif' : 'Inactif'}</div>,
    },
    {
      Header: t("Actions"),
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "8px" }}>
          {/* History button */}
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDelete(row.original.id)}
            title={t("DeleteAction")}
          >
            <i class="ri-delete-bin-line"></i>
          </Button>


          <Button
            color="primary"
            size="sm"
            onClick={() => goEditPage(row.original.id)}
            title={t("EditAction")}
          >
            <i
              className="ri-edit-line"
              style={{ fontSize: "16px" }}
            ></i>
          </Button>
        </div>
      ),
    },

  ];

  // Helper function to set different background colors for each status
  const getStatusBackgroundColor = (status) => {
    if (status === "Actif") return "green"; // Green for Approved
    if (status === "Inactif") return "red"; // Red for Canceled
  };

  const statusStyles = (status) => {
    return {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "8px",
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: "center",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light shadow to give a 3D effect
      margin: "4px 0",
      backgroundColor: getStatusBackgroundColor(status),
      color: "white",
    };
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const getItemRange = () => {
    const start = page * 10 + 1;
    const end = Math.min((page + 1) * 10, totalElements);
    return `${start}-${end} of ${totalElements}`;
  };

  const handleExportExcel = () => {
    let payload = {
      criteria,
      page: pageNumber,
      size: pageSize,
      onSuccess: (data) => {
        console.log(data);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = "transactions.xlsx";
        link.click();

        Swal.fire({
          icon: "success",
          title: "Exportation réussie",
          text: "Le fichier Excel des transactions a été généré et téléchargé avec succès.",
          confirmButtonText: "Super !",
        });
      },
      onError: (error) => {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "Échec de l'exportation",
          text: `Une erreur s'est produite lors de la génération du fichier des transactions. Veuillez réessayer plus tard.
                 Erreur : ${error.message || "Erreur inconnue"}`,

          confirmButtonText: "Ok",
        });
      },
    };
    //dispatch(transactionActions.generateExcelTransaction(payload));
  };

  const breadcrumbItems = [
    { title: t("Main"), link: "#" },
    { title: t("UserMain"), link: "#" },
  ];

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <Card className="shadow-sm border-0">
                <CardBody className="py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Left side - Title */}
                    <h4 className="mb-0 d-flex align-items-center">
                      <FaBox className="text-primary me-2" />
                      {t('users.main')}
                    </h4>

                    {/* Right side - Breadcrumb */}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <a href="/gestion-utilisateurs" className="text-decoration-none">
                            {t('users.main')}
                          </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="currentPage">
                          {t('users.main')}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="pagination-container">
                <span className="pagination-range">{getItemRange()}</span>
                <button
                  onClick={handlePreviousPage}
                  disabled={page === 0}
                  className="pagination-icon-button"
                >
                  <FaChevronLeft />
                </button>

                <button
                  onClick={handleNextPage}
                  disabled={page >= totalPages - 1}
                  className="pagination-icon-button"
                >
                  <FaChevronRight />
                </button>
              </div>

              <TableContainer
                columns={columns}
                data={users}
                isPagination={false}
                isAddPurchaseOrdersList={true}
                customPageSizeOptions={true}
                iscustomPageSize={false}
                handlePurchaseOrderclick={() => navigate("/gestion-utilisateurs/form")}
                isBordered={false}
                customPageSize={10}
                selectedRowIds={selectedRowIds}
                setSelectedRowIds={setSelectedRowIds}
              />
            </CardBody>
          </Card>
        </Container>
      )}
    </React.Fragment>
  );
};

export default UserList;
