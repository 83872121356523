import axiosInstance from "./api_helper";

export const loginApi = async ({ username, password }) => {
  try {
    const response = await axiosInstance.post("/auth/login", {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Login failed");
    } else {
      throw new Error("Network error, please try again later");
    }
  }
};
