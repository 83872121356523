import { createModule } from "saga-slice";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchMasterData,
  createDistrict,
  createRegion,
  createPrefecture,
  createSousPrefecture,
  deleteDistrict,
  deleteRegion,
  deletePrefecture,
  deleteSousPrefecture,
  updateDistrict,
  updatePrefecture,
  updateRegion,
  updateSousPrefecture,
} from "../services/dataService";

const masterDataModule = createModule({
  name: "masterData",
  initialState: {
    data: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    loadMasterDataRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    createDistrict: (state) => {
      state.isLoading = true;
    },
    createRegion: (state) => {
      state.isLoading = true;
    },
    createPrefecture: (state) => {
      state.isLoading = true;
    },
    createSousPrefecture: (state) => {
      state.isLoading = true;
    },
    deleteDistrict: (state) => {
      state.isLoading = true;
    },
    deleteRegion: (state) => {
      state.isLoading = true;
    },
    deletePrefecture: (state) => {
      state.isLoading = true;
    },
    deleteSousPrefecture: (state) => {
      state.isLoading = true;
    },
    updateDistrict: (state) => {
      state.isLoading = true;
    },
    updateRegion: (state) => {
      state.isLoading = true;
    },
    updatePrefecture: (state) => {
      state.isLoading = true;
    },
    updateSousPrefecture: (state) => {
      state.isLoading = true;
    },
    loadMasterDataSuccess: (state, payload) => {
      state.isLoading = false;
      state.data = payload;
    },
    loadMasterDataFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    fetchError: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
  sagas: (A) => ({
    *[A.loadMasterDataRequest]({ payload }) {
      try {
        const response = yield call(fetchMasterData);
        yield put(A.loadMasterDataSuccess(response.body));
      } catch (error) {
        yield put(A.loadMasterDataFailure(error.message));
      }
    },
    *[A.createDistrict]({ payload }) {
      console.log(payload);
      try {
        const { status } = yield call(createDistrict, payload.data);
        // Reload the data after creating
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.createRegion]({ payload }) {
      console.log(payload);
      try {
        const { status } = yield call(createRegion, payload.data);
        // Reload the data after creating
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.createPrefecture]({ payload }) {
      console.log(payload);
      try {
        const { status } = yield call(createPrefecture, payload.data);
        // Reload the data after creating
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.createSousPrefecture]({ payload }) {
      console.log(payload);
      try {
        const { status } = yield call(createSousPrefecture, payload.data);
        // Reload the data after creating
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.deleteDistrict]({ payload }) {
      console.log(payload);
      try {
        yield call(deleteDistrict, payload.id);

        if (payload.onSuccess) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.deleteRegion]({ payload }) {
      console.log(payload);
      try {
        yield call(deleteRegion, payload.id);

        if (payload.onSuccess) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.deletePrefecture]({ payload }) {
      console.log(payload);
      try {
        yield call(deletePrefecture, payload.id);

        if (payload.onSuccess) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.deleteSousPrefecture]({ payload }) {
      console.log(payload);
      try {
        yield call(deleteSousPrefecture, payload.id);

        if (payload.onSuccess) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.updateDistrict]({ payload }) {
      try {
        const { status } = yield call(updateDistrict, payload.id, payload.data);
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.updateRegion]({ payload }) {
      try {
        const { status } = yield call(updateRegion, payload.id, payload.data);
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.updatePrefecture]({ payload }) {
      try {
        const { status } = yield call(
          updatePrefecture,
          payload.id,
          payload.data
        );
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
    *[A.updateSousPrefecture]({ payload }) {
      try {
        const { status } = yield call(
          updateSousPrefecture,
          payload.id,
          payload.data
        );
        if (status === 200) {
          yield call(payload.onSuccess);
          yield put(A.loadMasterDataRequest());
        }
      } catch (error) {
        yield put(A.fetchError(error.message));
      }
    },
  }),
});
export const masterDataActions = masterDataModule.actions;
export default masterDataModule;
