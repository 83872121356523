import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Table, Button, Modal, ModalHeader,
  ModalBody, ModalFooter, Form, FormGroup, Label, Input,
  Badge
} from 'reactstrap';
import { FaPlus, FaEdit, FaTrash, FaCalendarAlt, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { campagneActions } from '../../../store/campagneSlice';
import { useTranslation } from 'react-i18next';
import "../../../assets/scss/tabs.css";
import * as XLSX from 'xlsx';

const Campagnes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { campagnes, isFetching } = useSelector(state => state.campagnes);
  const [modal, setModal] = useState(false);
  const [selectedCampagne, setSelectedCampagne] = useState(null);
  const [formData, setFormData] = useState({
    annee: '',
    actif: false
  });

  useEffect(() => {
    dispatch(campagneActions.search({ page: 0, size: 10 }));
  }, [dispatch]);

  const toggle = () => {
    setModal(!modal);
    if (modal) {
      setFormData({ annee: '', actif: false });
      setSelectedCampagne(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      dto: formData,
      id: selectedCampagne?.id,
      onSuccess: () => {
        toggle();
        dispatch(campagneActions.search());
      }
    };
    dispatch(selectedCampagne ?
      campagneActions.update(payload) :
      campagneActions.create(payload)
    );
  };

  // Add export function
  const handleExport = async () => {

    Swal.fire({
      title: t('campagne.export.loading'),
      text: t('campagne.export.wait'),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let playload = {
      onSuccess: (data) => {
        // Format data for Excel
        const excelData = data.map(campagne => ({
          'Année': campagne.annee,
          'Statut': campagne.actif ? 'Actif' : 'Inactif'
        }));

        // Create worksheet
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Create workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Campagnes');

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create download link
        const fileName = `campagnes_${new Date().toISOString().split('T')[0]}.xlsx`;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();

        Swal.fire({
          title: t('campagne.export.successTitle'),
          text: t('campagne.export.successText'),
          icon: 'success',
          timer: 1500
        });

      }, onError: (error) => {
        Swal.fire({
          title: t('campagne.export.errorTitle'),
          text: t('campagne.export.errorText'),
          icon: 'error'
        });
      }

    }


    dispatch(campagneActions.findAll(playload));



  };


  const handleEdit = (campagne) => {
    setSelectedCampagne(campagne);
    setFormData({ annee: campagne.annee, actif: campagne.actif || false });
    toggle();
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t('campagne.delete.title'),
      text: t('campagne.delete.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('campagne.delete.confirm'),
      cancelButtonText: t('campagne.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(campagneActions.delete({
          id,
          onSuccess: () => dispatch(campagneActions.search())
        }));
      }
    });
  };

  return (
    <Card className="shadow-sm">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h4 className="card-title mb-1">{t('campagne.title')}</h4>
            <p className="text-muted small mb-0">{t('campagne.description')}</p>
          </div>
          <div className="d-flex gap-2">
            <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
              <FaFileExcel className="me-2" /> {t('campagne.download')}
            </Button>
            <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
              <FaPlus className="me-2" /> {t('campagne.new')}
            </Button>
          </div>
        </div>

        {isFetching ? (
          <div className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : campagnes.length === 0 ? (
          <div className="text-center py-5 text-muted">
            <FaCalendarAlt size={32} className="mb-2" />
            <p>{t('campagne.noData')}</p>
          </div>
        ) : (
          <Table hover responsive className="align-middle">
            <thead className="bg-light">
              <tr>
                <th>{t('campagne.year')}</th>
                <th>{t('campagne.status')}</th>
                <th className="text-end">{t('campagne.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {campagnes.map(campagne => (
                <tr key={campagne.id}>
                  <td>{campagne.annee}</td>
                  <td>
                    <Badge color={campagne.actif ? "success" : "secondary"}>
                      {campagne.actif ? t('campagne.active') : t('campagne.inactive')}
                    </Badge>
                  </td>
                  <td className="text-end">
                    <Button color="info" size="sm" className="me-2" onClick={() => handleEdit(campagne)}>
                      <FaEdit />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => handleDelete(campagne.id)}>
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
          <ModalHeader toggle={toggle} className="bg-light">
            <FaCalendarAlt className="me-2" />
            {selectedCampagne ? t('campagne.edit') : t('campagne.new')}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody className="p-4">
              <FormGroup>
                <Label for="annee" className="form-label">
                  {t('campagne.year')} <span className="text-danger">*</span>
                </Label>
                <Input
                  id="annee"
                  name="annee"
                  className="form-control-lg"
                  value={formData.annee}
                  onChange={e => setFormData({ ...formData, annee: e.target.value })}
                  placeholder={t('campagne.yearPlaceholder')}
                  pattern="\d{4}"
                  required
                />
                <small className="text-muted">{t('campagne.yearHelp')}</small>
              </FormGroup>
              <FormGroup className="mt-3">
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="actif"
                    checked={formData.actif}
                    onChange={e => setFormData({ ...formData, actif: e.target.checked })}
                  />
                  <Label className="form-check-label" for="actif">
                    {t('campagne.setActive')}
                  </Label>
                </div>
                <small className="text-muted d-block mt-1">
                  {t('campagne.activeHelp')}
                </small>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="bg-light">
              <Button color="secondary" onClick={toggle}>
                {t('campagne.cancel')}
              </Button>
              <Button color="primary" type="submit">
                {t('campagne.save')}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default Campagnes;