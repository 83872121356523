import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import campagneService from "../services/campagneService";

const campagneSlice = createModule({
  name: "campagnes",
  initialState: {
    campagnes: [],
    isFetching: false,
    error: null,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 5,
  },
  reducers: {
    search: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    findAll: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    create: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    update: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    delete: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.error = null;
      if (action.body.content) {
        state.campagnes = action.body.content;
        state.totalElements = action.body.totalElements;
        state.totalPages = action.body.totalPages;
        state.pageNumber = action.body.pageNumber;
      }else{
        state.campagnes = action.body;
      } 
    },
    failure: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
  },
  sagas: (actions) => ({
    [actions.search]: function* ({ payload }) {
      try {
        const data = yield call(campagneService.search, payload);
        yield put(actions.success(data));
      } catch (error) {
        yield put(actions.failure(error.message));
      }
    },
    [actions.findAll]: function* ({ payload }) {
      try {
        const {onSuccess } = payload;
        const data = yield call(campagneService.findAll);
        yield put(actions.success(data));
        if (onSuccess) onSuccess(data.body);
      } catch (error) {
        yield put(actions.failure(error.message));
      }
    },
    [actions.create]: function* ({ payload }) {
      try {
        const { dto, onSuccess } = payload;
        const data = yield call(campagneService.create, dto);
        yield put(actions.success(data));
        if (onSuccess) onSuccess();
      } catch (error) {
        yield put(actions.failure(error.message));
      }
    },
    [actions.update]: function* ({ payload }) {
      try {
        const { id, dto, onSuccess } = payload;
        const data = yield call(campagneService.update, id, dto);
        yield put(actions.success(data));
        if (onSuccess) onSuccess();
      } catch (error) {
        yield put(actions.failure(error.message));
      }
    },
    [actions.delete]: function* ({ payload }) {
      try {
        const { id, onSuccess } = payload;
        yield call(campagneService.remove, id);
        if (onSuccess) onSuccess();
      } catch (error) {
        yield put(actions.failure(error.message));
      }
    }
  })
});

export const campagneActions = campagneSlice.actions;
export default campagneSlice;