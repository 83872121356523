import { combineReducers } from 'redux';

// Frontend layout
import Layout from './layout/reducer';

// Feature Modules
import producteursSlice from './producteursSlice';

// Root Reducer
import { rootReducer } from 'saga-slice';
import authModule from './authSlice';
import userModule from './userSlice';
import masterDataModule from './dataSlice';
import cooperativesSlice from './cooperativeSlice';
import districtSlice from './districtSlice';
import campagneSlice from './campagneSlice';
import cultureSlice from './cultureSlice';
import varieteSlice from './varieteSlice';
import typePieceSlice from './typePieceSlice';
import regionSlice from './regionSlice';
import prefectureSlice from './prefectureSlice';
import sousPrefectureSlice from './sousPrefectureSlice';
import zoneSlice from './zoneSlice';
import sectionSlice from './sectionSlice';
import faitiereSlice from './faitiereSlice';
import ethnieSlice from './ethnieSlice';

// Define all saga-slice modules
const modules = [producteursSlice, authModule, userModule, masterDataModule, cooperativesSlice, districtSlice, campagneSlice, cultureSlice,varieteSlice, typePieceSlice, regionSlice, prefectureSlice, sousPrefectureSlice, zoneSlice, sectionSlice, faitiereSlice, cooperativesSlice, ethnieSlice];

// Combine reducers and modules
const appReducer = rootReducer(modules, {
  // Public reducers
  Layout,
});

// Optional: Add a root-level reducer to handle global state reset (e.g., on logout)
const rootReducerWrapper = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined; // Clear all state
  }
  return appReducer(state, action);
};

export default rootReducerWrapper;
