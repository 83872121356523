import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

//translations
const resources = {

  fr: {
    translation: {

      "ethnie": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "delete": {
          "confirmTitle": "Supprimer l'ethnie",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette ethnie?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "L'ethnie a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "title": "Ethnies",
        "new": "Nouvelle Ethnie",
        "edit": "Modifier Ethnie",
        "code": "Code",
        "description": "Description",
        "status": "Statut",
        "actions": "Actions",
        "active": "Active",
        "inactive": "Inactive",

        "create": {
          "successTitle": "Création réussie",
          "successText": "L'ethnie a été créée avec succès"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "L'ethnie a été modifiée avec succès"
        },
        "save": "Enregistrer",
        "cancel": "Annuler"
      },
      actions: {
        title: "Action",
        save: "Enregistrer",
        delete: "Supprimer",
        edit: "Modifier",
        login: "Login",
        logout: "Logout",
        downloadTemp: "Template",
        export: "Exporter",
        upload: "Données",
        close: "Annuler",
        confirm: "Confirmer",
        new: "Créer",
        showing: "Affichage de {{start}} à {{end}} sur {{total}} résultats (page {{currentPage}} de {{totalPages}})",

      },
      producteur: {
        fiche: "Fiche Producteur",
        main: "Gestion des Producteurs",
      },
      users: {
        main: "Gestion des Utilisateurs",
        fiche: "Fiche Utilisateur",
        add: "Ajouter un Utilisateur",
      },
      "database": {
        "title": "Gestion des Données",
        "subtitle": "Gérer les données de référence de l'application",
        "tabs": {
          "campaigns": "Campagnes",
          "cultures": "Cultures Agricoles",
          "varieties": "Variétés Semences",
          "documents": "Type Pièces",
          "districts": "Districts",
          "regions": "Régions",
          "prefectures": "Préfectures",
          "subPrefectures": "Sous-Préfectures",
          "localities": "Localités",
          "zones": "Zones",
          "sections": "Sections",
          "cooperatives": "Coopératives",
          "ethnicGroups": "Ethnies",
          "faitieres": "Faitières"
        },
        "actions": {
          "new": "Nouveau",
          "edit": "Modifier",
          "delete": "Supprimer",
          "save": "Enregistrer",
          "cancel": "Annuler"
        },
        "messages": {
          "confirmDelete": "Êtes-vous sûr de vouloir supprimer cet élément?",
          "deleteSuccess": "Élément supprimé avec succès",
          "saveSuccess": "Élément enregistré avec succès",
          "error": "Une erreur est survenue"
        },
        "validation": {
          "required": "Ce champ est obligatoire",
          "invalidFormat": "Format invalide"
        }
      },
      "culture": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Cultures Agricoles",
        "description": "Gérer les différentes cultures agricoles et leurs caractéristiques techniques",
        "new": "Nouvelle Culture",
        "edit": "Modifier Culture",
        "code": "Code Culture",
        "name": "Nom Culture",
        "technicalRoute": "Itinéraire Technique",
        "stockCode": "Code Article Stock",
        "status": "Statut",
        "active": "Actif",
        "inactive": "Inactif",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "delete": {
          "title": "Supprimer la culture",
          "text": "Êtes-vous sûr de vouloir supprimer cette culture?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "success": "Culture supprimée avec succès",
          "error": "Erreur lors de la suppression",
          "successTitle": "Succès",
          "successText": "La culture a été supprimée avec succès",
          "errorTitle": "Erreur",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire"
        },
        "messages": {
          "saveSuccess": "Culture enregistrée avec succès",
          "saveError": "Erreur lors de l'enregistrement"
        },
        "create": {
          "successTitle": "Création réussie",
          "successText": "La culture a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La culture a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },

      },
      "typePiece": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Types de Pièces",
        "description": "Gestion des types de pièces et documents",
        "new": "Nouveau Type",
        "edit": "Modifier Type",
        "code": "Code",
        "libelle": "Libellé",
        "status": "Statut",
        "active": "Actif",
        "inactive": "Inactif",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "create": {
          "successTitle": "Création réussie",
          "successText": "Le type de pièce a été créé avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "Le type de pièce a été modifié avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer le type",
          "confirmText": "Êtes-vous sûr de vouloir supprimer ce type de pièce?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "Le type de pièce a été supprimé avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "libelleRequired": "Le libellé est obligatoire"
        }
      },
      "district": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Districts",
        "description": "Gestion des districts et leurs informations",
        "new": "Nouveau District",
        "edit": "Modifier District",
        "code": "Code District",
        "name": "Nom District",
        "status": "Statut",
        "active": "Actif",
        "inactive": "Inactif",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "noData": "Aucun district trouvé",
        "loadingData": "Chargement des districts...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "Le district a été créé avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "Le district a été modifié avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer le district",
          "confirmText": "Êtes-vous sûr de vouloir supprimer ce district?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "Le district a été supprimé avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant"
        }
      },
      "variete": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Variétés de Semences",
        "description": "Gestion des variétés de semences et leurs caractéristiques",
        "new": "Nouvelle Variété",
        "edit": "Modifier Variété",
        "code": "Code Variété",
        "name": "Nom Variété",
        "details": "Détails",
        "culture": "Culture",
        "yield": "Rendement (t/ha)",
        "stockCode": "Code Article Stock",
        "actions": "Actions",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La variété a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La variété a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la variété",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette variété?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La variété a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "cultureRequired": "La culture est obligatoire",
          "yieldFormat": "Le rendement doit être un nombre"
        }
      },
      "common": {
        showing: "Affichage de {{start}} à {{end}} sur {{total}} résultats (page {{currentPage}} de {{totalPages}})",
        send: "Envoyer un message",
        "loading": "Chargement...",
        "loadingData": "Chargement des données...",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "edit": "Modifier",
        "delete": "Supprimer",
        confirm: "Confirmer",
        "close": "Fermer",
        "download": "Exporter",
      },
      "campagne": {

        "title": "Gestion des Campagnes",
        "description": "Gérer les campagnes agricoles",
        "new": "Nouvelle Campagne",
        "edit": "Modifier Campagne",
        "year": "Année",
        "yearPlaceholder": "Entrez l'année (YYYY)",
        "yearHelp": "Format: YYYY (ex: 2024)",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "noData": "Aucune campagne disponible",
        "loading": "Chargement...",
        "delete": {
          "title": "Supprimer la campagne",
          "text": "Êtes-vous sûr de vouloir supprimer cette campagne?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "success": "Campagne supprimée avec succès",
          "error": "Erreur lors de la suppression"
        },
        "download": "Exporter",

        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "validation": {
          "yearRequired": "L'année est obligatoire",
          "yearFormat": "Format d'année invalide (YYYY)"
        },
        "messages": {
          "saveSuccess": "Campagne enregistrée avec succès",
          "saveError": "Erreur lors de l'enregistrement"
        }
      },
      "region": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Régions",
        "description": "Gestion des régions et leurs rattachements aux districts",
        "new": "Nouvelle Région",
        "edit": "Modifier Région",
        "code": "Code Région",
        "name": "Nom Région",
        "district": "District",
        "coordinates": "Coordonnées GPS",
        "selectDistrict": "Sélectionner un district",
        "noDistrictSelected": "Aucun district sélectionné",
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "select": "Sélectionner",
        "noData": "Aucune région trouvée",
        "loadingData": "Chargement des régions...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La région a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La région a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la région",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette région?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La région a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "districtRequired": "Le district est obligatoire",
          "coordinatesFormat": "Format: latitude, longitude"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        },
        "modal": {
          "districtTitle": "Sélection du district",
          "search": "Rechercher un district",
          "noResults": "Aucun district trouvé"
        }
      },
      "prefecture": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Préfectures",
        "description": "Gestion des préfectures et leurs rattachements aux régions",
        "new": "Nouvelle Préfecture",
        "edit": "Modifier Préfecture",
        "code": "Code Préfecture",
        "name": "Nom Préfecture",
        "region": "Région",
        "coordinates": "Coordonnées GPS",
        "selectRegion": "Sélectionner une région",
        "noRegionSelected": "Aucune région sélectionnée",
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "select": "Sélectionner",
        "noData": "Aucune préfecture trouvée",
        "loadingData": "Chargement des préfectures...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La préfecture a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La préfecture a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la préfecture",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette préfecture?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La préfecture a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "regionRequired": "La région est obligatoire",
          "coordinatesFormat": "Format: latitude, longitude"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        },
        "modal": {
          "regionTitle": "Sélection de la région",
          "search": "Rechercher une région",
          "noResults": "Aucune région trouvée"
        }
      },
      "zone": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Zones",
        "description": "Gestion des zones et leurs responsables",
        "new": "Nouvelle Zone",
        "edit": "Modifier Zone",
        "code": "Code Zone",
        "name": "Nom Zone",
        "chiefId": "Matricule Chef de Zone",
        "chiefName": "Nom Chef de Zone",
        "contact": "Contact",
        "email": "Email",
        "coordinates": "Coordonnées GPS",
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "select": "Sélectionner",
        "noData": "Aucune zone trouvée",
        "loadingData": "Chargement des zones...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La zone a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La zone a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la zone",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette zone?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La zone a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "emailFormat": "Format d'email invalide",
          "contactFormat": "Format de contact invalide",
          "coordinatesFormat": "Format: latitude, longitude"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        }
      },
      "nav": {
        "dashboard": "Tableau de bord",
        "basicData": "Données de Base",
        "producers": "Producteurs",
        "transactions": "Transactions",
        "reporting": "Reporting",
        "settings": "Paramètres",
        "users": "Utilisateurs",
        "roles": "Rôles",
        "permissions": "Permissions",
        "logout": "Déconnexion"
      },

      auth: {
        welcomeSignup: "Bienvenue sur MatjarPos",
        createAccount: "Créez votre compte pour commencer",
        businessInfo: "Informations de l'entreprise",
        personalInfo: "Informations personnelles",
        businessName: "Nom de l'entreprise",
        businessNamePlaceholder: "Entrez le nom de votre entreprise",
        address: "Adresse",
        addressPlaceholder: "Adresse complète de votre entreprise",
        phone: "Téléphone",
        phonePlaceholder: "+212 XXX-XXXXXX",
        email: "Email",
        emailPlaceholder: "exemple@domaine.com",
        username: "Nom d'utilisateur",
        usernamePlaceholder: "Choisissez un nom d'utilisateur",
        password: "Mot de passe",
        passwordPlaceholder: "Choisissez un mot de passe sécurisé",
        contactPreferences: "Préférences de contact",
        whatsapp: "WhatsApp",
        emailContact: "Email",
        sms: "SMS",
        credentialsInfo: "Après validation de votre compte, vos identifiants de connexion vous seront envoyés par email",
        signupButton: "Créer mon compte",
        haveAccount: "Déjà un compte ?",
        loginLink: "Se connecter",
        required: "Ce champ est obligatoire",
        invalidEmail: "Adresse email invalide",
        invalidPhone: "Numéro de téléphone invalide",
        passwordMin: "Le mot de passe doit contenir au moins 8 caractères",
        validationInfo: "Après vérification de votre compte,",
        credentialsVia: "vos accès vous seront envoyés via",
        selectContact: "veuillez sélectionner au moins un moyen de contact",
        welcomeBack: "Ravi de vous revoir",
        loginPrompt: "Connectez-vous à votre compte",
        username: "Nom d'utilisateur",
        usernamePlaceholder: "Entrez votre nom d'utilisateur",
        password: "Mot de passe",
        passwordPlaceholder: "Entrez votre mot de passe",
        loginButton: "Se connecter",
        noAccount: "Vous n'avez pas de compte ?",
        signupLink: "Créer un compte",
        invalidCredentials: "Nom d'utilisateur ou mot de passe incorrect",
        forgotPassword: "Mot de passe oublié ?",
        rememberMe: "Se souvenir de moi",
        logoutConfirmTitle: "Déconnexion",
        logoutConfirmText: "Êtes-vous sûr de vouloir vous déconnecter ?",
        logoutSuccess: "Vous avez été déconnecté avec succès"
      },
      "user": {
        "greeting": "Bonjour",
        signOut: "Déconnexion"

      },
      "dashboard": {
        "pageTitle": "Tableau de Bord | AgriTracking",
      },
      "cooperative": {
        "title": "Coopératives",
        "description": "Gestion des coopératives et leurs membres",
        "status": "Statut",
        "contact": "Contact",
        "active": "Active",
        "president": "Président",
        "code": "Code",
        "name": "Nom",
        "actions": "Actions",
        "inactive": "Inactive",
        "inactive": "Inactive",
        "new": "Nouvelle Coopérative",
        "edit": "Modifier Coopérative",
        "form": {
          "basic": {
            "code": "Code Coopérative",
            "name": "Nom Coopérative",
            "headquarters": "Siège Social",
            "description": "Description"
          },
          "dates": {
            "creation": "Date de Création",
            "partnershipStart": "Date de Début du Partenariat"
          },
          "leadership": {
            "president": {
              "name": "Nom et Prénoms du Président",
              "phone": "Téléphone du Président"
            },
            "manager": {
              "name": "Nom et Prénoms du Gérant",
              "phone": "Téléphone du Gérant"
            },
            "accountant": {
              "name": "Nom et Prénoms du Comptable",
              "phone": "Téléphone du Comptable"
            },
            "secretaryGeneral": {
              "name": "Nom et Prénoms du Secrétaire Général",
              "phone": "Téléphone du Secrétaire Général"
            },
            "treasurer": {
              "name": "Nom et Prénoms du Trésorier",
              "phone": "Téléphone du Trésorier"
            }
          },
          "representatives": {
            "first": {
              "name": "Nom et Prénoms du 1er Mandataire",
              "phone": "Téléphone du 1er Mandataire"
            },
            "second": {
              "name": "Nom et Prénoms du 2ème Mandataire",
              "phone": "Téléphone du 2ème Mandataire"
            }
          },
          "members": {
            "male": "Nombre de Membres Hommes",
            "female": "Nombre de Membres Femmes"
          },
          "bank": {
            "name": "Nom de la Banque",
            "rib": "RIB Bancaire"
          },
          "location": {
            "headquarters": "Siège Social",
          }
        },
        "validation": {
          "required": "Ce champ est obligatoire",
          "phoneFormat": "Format de téléphone invalide",
          "positiveNumber": "Le nombre doit être positif",
          "dateFormat": "Format de date invalide"
        }
      },
      "faitiere": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Organisations Faîtières",
        "description": "Gestion des organisations faîtières et leurs membres",
        "new": "Nouvelle Organisation Faîtière",
        "edit": "Modifier Organisation Faîtière",
        "code": "Code Faîtière",
        "name": "Nom Faîtière",
        "contact": "Contact",
        "email": "Email",
        "creationDate": {
          "label": "Date de Création",
          "placeholder": "JJ/MM/AAAA",
          "help": "Date de création de l'organisation",
          "format": "Format: JJ/MM/AAAA"
        },
        "partnership": {
          "startDate": "Date Début Partenariat",
          "selectDate": "Sélectionner la date"
        },
        "representative": {
          "fullName": "Nom et Prénoms du Représentant",
          "contact": "Contact du Représentant",
          "email": "Email"
        },
        "members": {
          "male": "Nombre de Membres Hommes",
          "female": "Nombre de Membres Femmes",
          "total": "Total des Membres"
        },
        "headquarters": "Siège Social",
        "description": "Description",
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "noData": "Aucune organisation faîtière trouvée",
        "loadingData": "Chargement des organisations...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "L'organisation faîtière a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "L'organisation faîtière a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer l'organisation",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette organisation?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "L'organisation faîtière a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "representativeRequired": "Les informations du représentant sont requises",
          "emailFormat": "Format d'email invalide",
          "contactFormat": "Format de contact invalide",
          "membersPositive": "Le nombre de membres doit être positif"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        }
      },
      "section": {
        "title": "Sections",
        "description": "Gestion des sections et leurs superviseurs",
        "new": "Nouvelle Section",
        "edit": "Modifier Section",
        "code": "Code Section",
        "name": "Nom Section",
        "contact": "Contact",
        "email": "Email",
        "zone": "Zone",
        "selectZone": "Sélectionner une Zone",
        "noZoneSelected": "Aucune zone sélectionnée",
        "coordinates": "Coordonnées GPS",
        "supervisor": {
          "id": "Matricule Superviseur",
          "name": "Nom Superviseur",
          "contact": "Contact",
          "email": "Email",
          "residence": "Lieu de Résidence"
        },
        "localities": {
          "title": "Localités en charge",
          "add": "Ajouter une localité",
          "remove": "Retirer",
          "empty": "Aucune localité",
          "enterLocality": "Entrez le nom de la localité",
          "confirmRemove": "Voulez-vous retirer cette localité?"
        },
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "select": "Sélectionner",
        "noData": "Aucune section trouvée",
        "loadingData": "Chargement des sections...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La section a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La section a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la section",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette section?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La section a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "zoneRequired": "La zone est obligatoire",
          "emailFormat": "Format d'email invalide",
          "contactFormat": "Format de contact invalide",
          "coordinatesFormat": "Format: latitude, longitude",
          "supervisorRequired": "Les informations du superviseur sont requises"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        }
      },
      "sousPrefecture": {
        "export": {
          "loading": "Export en cours",
          "wait": "Veuillez patienter...",
          "successTitle": "Export réussi",
          "successText": "Les données ont été exportées avec succès",
          "errorTitle": "Erreur d'export",
          "errorText": "Une erreur est survenue lors de l'export"
        },
        "title": "Sous-Préfectures",
        "description": "Gestion des sous-préfectures et leurs rattachements",
        "new": "Nouvelle Sous-Préfecture",
        "edit": "Modifier Sous-Préfecture",
        "code": "Code Sous-Préfecture",
        "name": "Nom Sous-Préfecture",
        "district": "District",
        "region": "Région",
        "prefecture": "Préfecture",
        "coordinates": "Coordonnées GPS",
        "selectDistrict": "Sélectionner un district",
        "selectRegion": "Sélectionner une région",
        "selectPrefecture": "Sélectionner une préfecture",
        "noDistrictSelected": "Aucun district sélectionné",
        "noRegionSelected": "Aucune région sélectionnée",
        "noPrefectureSelected": "Aucune préfecture sélectionnée",
        "status": "Statut",
        "active": "Active",
        "inactive": "Inactive",
        "actions": "Actions",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "select": "Sélectionner",
        "noData": "Aucune sous-préfecture trouvée",
        "loadingData": "Chargement des sous-préfectures...",
        "create": {
          "successTitle": "Création réussie",
          "successText": "La sous-préfecture a été créée avec succès",
          "errorTitle": "Erreur de création",
          "errorText": "Une erreur est survenue lors de la création"
        },
        "update": {
          "successTitle": "Modification réussie",
          "successText": "La sous-préfecture a été modifiée avec succès",
          "errorTitle": "Erreur de modification",
          "errorText": "Une erreur est survenue lors de la modification"
        },
        "delete": {
          "confirmTitle": "Supprimer la sous-préfecture",
          "confirmText": "Êtes-vous sûr de vouloir supprimer cette sous-préfecture?",
          "confirm": "Oui, supprimer",
          "cancel": "Annuler",
          "successTitle": "Suppression réussie",
          "successText": "La sous-préfecture a été supprimée avec succès",
          "errorTitle": "Erreur de suppression",
          "errorText": "Une erreur est survenue lors de la suppression"
        },
        "validation": {
          "codeRequired": "Le code est obligatoire",
          "nameRequired": "Le nom est obligatoire",
          "districtRequired": "Le district est obligatoire",
          "regionRequired": "La région est obligatoire",
          "prefectureRequired": "La préfecture est obligatoire",
          "coordinatesFormat": "Format: latitude, longitude"
        },
        "pagination": {
          "showing": "Affichage de",
          "to": "à",
          "of": "sur",
          "entries": "entrées",
          "previous": "Précédent",
          "next": "Suivant",
          "first": "Premier",
          "last": "Dernier"
        },
        "hierarchy": {
          "selectInOrder": "Veuillez sélectionner dans l'ordre: District → Région → Préfecture",
          "districtFirst": "Veuillez d'abord sélectionner un district",
          "regionFirst": "Veuillez d'abord sélectionner une région"
        }
      },

    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
