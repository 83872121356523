const dev = {
  baseURL: "http://localhost:8060", // Development base URL
};

const prod = {
  baseURL: "https://admin.wodisgroup.com", // Production base URL
};

// Determine which environment we're in and export the appropriate settings
const config = process.env.NODE_ENV === "production" ? prod : dev;

export default config;
