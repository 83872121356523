import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaMapMarkerAlt, FaInfoCircle, FaBox, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaTag, FaCog, FaBarcode, FaQrcode } from "react-icons/fa"; // Import icons
import ReactSelect from "react-select";

import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { producteursActions } from "../../store/producteursSlice";
import config from "../../helpers/config";
import "../../assets/styles.css"; // Adjust the path to your CSS file
import { useTranslation } from "react-i18next";
import TableContainer from "../../components/Common/TableContainer";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const DEFAULT_COORDINATES = { latitude: 5.30966, longitude: -4.01266 }; // Abidjan

const GestionProducteurs = () => {
  const dispatch = useDispatch();
  const {
    producteurs,
    isFetching,
    totalPages,
    totalElements,
    pageNumber,
    pageSize,
    error,
  } = useSelector((state) => state.producteurs);
  const { t } = useTranslation("translation");
  const [currentPage, setCurrentPage] = useState(pageNumber); // Track current currentPage

  const masterData = useSelector((state) => state.masterData.data);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    latitude: null,
    longitude: null,
  });
  const navigate = useNavigate();

  const [selectedProducteur, setSelectedProducteur] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);

  // Toggle the modal
  const toggleModal = () => setModal(!modal);
  const toggleMapModal = () => setMapModal(!mapModal);
  const toggleDetailsModal = () => setDetailsModal(!detailsModal);

  // Local state for search form
  const [criteria, setSearchCriteria] = useState({
    campagneId: "",
    codeProducteur: "",
    genre: "",
    districtId: "",
    regionId: "",
    perfectureId: "",
    sousPerfectureId: "",
    zoneId: "",
    faitiereId: "",
    cooperativeId: "",
    nationalite: "",
    cultureAgricoleId: "",
    localiteId: "",
    latitude: "",
    longitude: "",
    address: "",
  });

  // Handle search form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    let payload = {
      criteria,
      currentPage,
      size: pageSize,
    };
    dispatch(producteursActions.search(payload));
  }, [currentPage]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      text: "Cette action supprimera définitivement cette utilisateur. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id,
          onSuccess: () => {
            dispatch(
              producteursActions.search({
                criteria,
                currentPage: currentPage,
                size: pageSize,
              })
            );
            Swal.fire({
              title: "Supprimé !",
              text: "Producteur a été supprimée avec succès.",
              icon: "success",
              confirmButtonText: "OK",
            });
          },
          onError: (error) => {
            Swal.fire({
              title: "Erreur !",
              text:
                error.message ||
                "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
              icon: "error",
              confirmButtonText: "OK",
            });
            console.log("Error : ", error);
          },
        };

        dispatch(producteursActions.delete(payload));
        console.log(payload);
      }
    });
  };

  // Map master data descriptions
  const cultureAgricoleDescription = (producteur) =>
    masterData?.culturesAgricoles?.find(
      (culture) => culture.id === producteur.cultureAgricoleId
    )?.nomCulture || "N/A";

  const districtDescription = (producteur) =>
    masterData?.districts?.find(
      (district) => district.id === producteur.districtId
    )?.description || "N/A";

  const regionDescription = (producteur) =>
    masterData?.regions?.find((region) => region.id === producteur.regionId)
      ?.description || "N/A";

  const prefectureDescription = (producteur) =>
    masterData?.perfectures?.find(
      (prefecture) => prefecture.id === producteur.prefectureId
    )?.nomPrefecture || "N/A";

  const sousPrefectureDescription = (producteur) =>
    masterData?.sousPerfectures?.find(
      (sousPrefecture) => sousPrefecture.id === producteur.sousPrefectureId
    )?.nomSousPrefecture || "N/A";

  const zoneDescription = (producteur) =>
    masterData?.zones?.find((zone) => zone.id === producteur.zoneId)
      ?.description || "N/A";

  const cooperativeDescription = (producteur) =>
    masterData?.cooperatives?.find(
      (cooperative) => cooperative.id === producteur.cooperativeId
    )?.description || "N/A";

  const faitiereDescription = (producteur) =>
    masterData?.faitieres?.find(
      (faitiere) => faitiere.id === producteur.faitiereId
    )?.description || "N/A";

  const coordinates = {
    latitude: selectedProducteur?.latitude || DEFAULT_COORDINATES.latitude,
    longitude: selectedProducteur?.longitude || DEFAULT_COORDINATES.longitude,
  };

  const goEditPage = (id) => {
    navigate(
      `/gestion-producteurs/form/${id}?token=${localStorage.getItem("token")}`
    );
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const getItemRange = () => {
    const start = currentPage * 10 + 1;
    const end = Math.min((currentPage + 1) * 10, totalElements);
    return `${start}-${end} of ${totalElements}`;
  };

  const breadcrumbItems = [
    { title: t("Main"), link: "#" },
    { title: t("producteur.main"), link: "#" },
  ];

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (<>
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <Card className="shadow-sm border-0">
                <CardBody className="py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Left side - Title */}
                    <h4 className="mb-0 d-flex align-items-center">
                      <FaBox className="text-primary me-2" />
                      {t('producteur.main')}
                    </h4>

                    {/* Right side - Breadcrumb */}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <a href="/gestion-producteurs" className="text-decoration-none">
                            {t('producteur.main')}
                          </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="currentPage">
                          {t('producteur.main')}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pe-4">
              <Card className="shadow-sm h-100">
                <CardBody>
                  {isFetching ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">{t('common.loading')}</span>
                      </div>
                      <div className="mt-2 text-muted">
                        {t('common.loadingData')}
                      </div>
                    </div>
                  ) : (
                    <>

                      <div className="table-responsive" style={{ maxWidth: '90%' }}>
                        <Table hover bordered className="align-middle mb-0">
                          <thead className="bg-light">
                            <tr>
                              <th className="align-middle">
                                <FaBarcode className="me-2 text-primary" size={12} />
                                {t('Photo')}
                              </th>
                              <th className="align-middle">
                                <FaBox className="me-2 text-primary" size={12} />
                                {t('DateCreation')}
                              </th>
                              <th className="align-middle text-center">
                                <FaQrcode className="me-2 text-primary" size={12} />
                                {t('codeProducteur')}
                              </th>

                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Fullname')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Genre')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('District')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Region')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Zone')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Perfecture')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('SubPerfecture')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Cooperative')}
                              </th>
                              <th className="align-middle text-end">
                                <FaTag className="me-2 text-primary" size={12} />
                                {t('Faitiere')}
                              </th>
                              <th className="align-middle text-center" style={{ width: '120px' }}>
                                <FaCog className="me-2 text-primary" size={12} />
                                {t('Actions')}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {!producteurs || producteurs.length === 0 ? (
                              <tr>
                                <td colSpan="6" className="text-center py-4">
                                  <div className="text-muted">
                                    {t('product.noProducts')}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              producteurs.map((producteur) => (
                                <tr key={producteur.id}>
                                  <td className="align-middle">
                                    {<React.Fragment>
                                      <img
                                        src={`${config.baseURL}${producteur.imageServerUrl}`}
                                        alt="Producteur"
                                        className="img-thumbnail profile-picture"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                          borderRadius: "30%",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setSelectedImage(
                                            `${config.baseURL}${producteur.imageServerUrl}`
                                          );
                                          toggleModal();
                                        }}
                                      />
                                    </React.Fragment>}
                                  </td>
                                  <td className="align-middle">{producteur.createdDate}</td>
                                  <td className="align-middle text-center">{producteur.codeProducteur}</td>
                                  <td className="align-middle text-end">{producteur.nomPrenoms}</td>
                                  <td className="align-middle text-end">{producteur.genre}</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{districtDescription(producteur)}</React.Fragment>

                                  }</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{regionDescription(producteur)}</React.Fragment>
                                  }</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{zoneDescription(producteur)}</React.Fragment>
                                  }</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{prefectureDescription(producteur)}</React.Fragment>
                                  }</td>
                                  <td className="align-middle text-end">{<React.Fragment>
                                    {sousPrefectureDescription(producteur)}
                                  </React.Fragment>}</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{cooperativeDescription(producteur)}</React.Fragment>
                                  }</td>
                                  <td className="align-middle text-end">{
                                    <React.Fragment>{faitiereDescription(producteur)}</React.Fragment>
                                  }</td>

                                  <td className="align-middle text-center">
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => handleDelete(producteur.id)}
                                      title={t("DeleteAction")}
                                    >
                                      <i class="ri-delete-bin-line"></i>
                                    </Button>

                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        const coordinates = {
                                          latitude: producteur.latitude || DEFAULT_COORDINATES.latitude,
                                          longitude:
                                            producteur.longitude || DEFAULT_COORDINATES.longitude,
                                        };
                                        setSelectedCoordinates(coordinates);
                                        toggleMapModal();
                                      }}
                                    >
                                      <FaMapMarkerAlt />
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        goEditPage(producteur.id);
                                      }}
                                      title={t("EditAction")}
                                    >
                                      <i className="ri-edit-line" style={{ fontSize: "16px" }}></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                          {t('common.showing', {
                            start: (currentPage - 1) * pageSize + 1,
                            end: Math.min(currentPage * pageSize, totalElements),
                            total: totalElements,
                            currentPage: currentPage,
                            totalPages: totalElements
                          })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink first onClick={() => handlePageChange(1)}>
                              <FaAngleDoubleLeft size={12} />
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                              <FaAngleLeft size={12} />
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                              <FaAngleRight size={12} />
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink last onClick={() => handlePageChange(totalPages)}>
                              <FaAngleDoubleRight size={12} />
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </>)}
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
        {/* Modal for Image Zoom */}
        <Modal isOpen={modal} toggle={toggleModal} centered>
          <ModalBody>
            <img
              src={selectedImage}
              alt="Zoomed Producteur"
              className="img-fluid"
            />
          </ModalBody>
        </Modal>
        {/* Map Modal */}
        <Modal
          isOpen={mapModal}
          toggle={toggleMapModal}
          className="modal-custom-size" // Custom size class
          // Add this class
          centered
        >
          <ModalHeader toggle={toggleMapModal}>
            Geolocation of Producteur
          </ModalHeader>
          <ModalBody className="modal-custom-body">
            {selectedCoordinates.latitude && selectedCoordinates.longitude ? (
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps?q=${selectedCoordinates.latitude},${selectedCoordinates.longitude}&hl=es;z=14&output=embed`}
                allowFullScreen
                title="Google Map"
              ></iframe>
            ) : (
              <p>Coordinates not available</p>
            )}
          </ModalBody>
        </Modal>
      </>
      )}
    </React.Fragment>
  );
};

export default GestionProducteurs;
