import React from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarText,
  Button,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import '../assets/scss/App.css'; // Optional for custom styling
import logo from "../assets/images/logo-dark.png";
import { useTranslation } from 'react-i18next';
import { FaBarcode, FaBox, FaBoxes, FaShoppingCart, FaTachometerAlt } from 'react-icons/fa';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { authActions } from '../store/authSlice';

const Layout = ({ children }) => {
  const { fullName } = useSelector((state) => state.auth);
  const role = useSelector((state) => state.auth.role);
  const location = useLocation();
  const isCurrentPath = (path) => location.pathname === path;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: t('auth.logoutConfirmTitle'),
      text: t('auth.logoutConfirmText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(authActions.logout());
        Swal.fire({
          icon: 'success',
          title: t('auth.logoutSuccess'),
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          navigate('/login');
        });
      }
    });
  };


  return (
    <div className="app-wrapper d-flex flex-column min-vh-100">
      {/* Header */}
      <header >
        <Navbar className="navbar-light bg-white shadow-lg px-4" expand="lg">
          <NavbarBrand href="/" className="d-flex align-items-center">
            <img
              src={logo}
              alt="WODIS"
              height="45"
              className="brand-logo"
            />
          </NavbarBrand>
          <NavbarText className="ms-auto d-flex align-items-center">
            <UncontrolledDropdown>
              <DropdownToggle nav caret className="d-flex align-items-center">
                <FaUser className="me-2" />
                {t('user.greeting')}, {fullName}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={handleLogout}>
                  <FaSignOutAlt className="me-2" />
                  {t('user.signOut')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavbarText>
        </Navbar>
      </header>

      <div className="content-wrapper d-flex flex-grow-1 bg-light">
        {/* Sidebar */}
        <aside className="sidebar bg-light border-end shadow-lg position-relative" style={{ zIndex: 1000 }}>
          <Nav vertical className="sidebar-nav p-3">
            {/* Show all menus for admin */}
            {role && role === 'ROLE_ADMIN' && (
              <>
                <NavItem className={isCurrentPath('/dashboard') ? 'active' : ''}>
                  <NavLink
                    href="/dashboard"
                    className={`
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isCurrentPath('/dashboard') ? 'bg-primary text-white' : 'text-secondary hover-primary'}
                  `}
                  >
                    <FaTachometerAlt className="me-3" />
                    {t('nav.dashboard')}
                  </NavLink>
                </NavItem>
                <NavItem className={isCurrentPath('/gestion-producteurs') ? 'active' : ''}>
                  <NavLink
                    href="/gestion-producteurs"
                    className={`
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isCurrentPath('/gestion-producteurs') ? 'bg-primary text-white' : 'text-secondary hover-primary'}
                  `}
                  >
                    <FaBox className="me-3" />
                    {t('nav.producers')}
                  </NavLink>
                </NavItem>
                <NavItem className={isCurrentPath('/gestion-donnees') ? 'active' : ''}>
                  <NavLink
                    href="/gestion-donnees"
                    className={`
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isCurrentPath('/gestion-donnees') ? 'bg-primary text-white' : 'text-secondary hover-primary'}
                  `}
                  >
                    <FaBoxes className="me-3" />
                    {t('nav.basicData')}
                  </NavLink>
                </NavItem>
                <NavItem className={isCurrentPath('/gestion-utilisateurs') ? 'active' : ''}>
                  <NavLink
                    href="/gestion-utilisateurs"
                    className={`
              d-flex align-items-center p-3 mb-2 rounded-3 text-decoration-none
              ${isCurrentPath('/gestion-utilisateurs') ? 'bg-primary text-white' : 'text-secondary hover-primary'}
                  `}
                  >
                    <FaUser className="me-3" />
                    {t('nav.users')}
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </aside>

        {/* Main Content */}
        <main className=" flex-grow-1 bg-light">
          <Container fluid className="p-4">
            {children}
          </Container>
        </main>
      </div>

      {/* Footer */}
      <footer className="footer bg-white border-top py-3">
        <Container fluid className="px-4">
          <Row className="align-items-center">
            <Col md={6} className="text-start">
              <span className="text-muted">AgriTracking v1.0</span>
            </Col>
            <Col md={6} className="text-end">
              <span className="text-muted">© {new Date().getFullYear()} Dévelopé par IDERACLOUD.</span>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;

// App.css
