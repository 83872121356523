import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "./reducers";
import { rootSaga } from "saga-slice";
import appSaga from "./sagas";
import producteursSlice from "./producteursSlice";
import  authModule  from "./authSlice";
import userModule from "./userSlice";
import masterDataModule from "./dataSlice";
import cooperativesSlice from "./cooperativeSlice";
import campagneSlice from "./campagneSlice";
import districtSlice from "./districtSlice";
import cultureSlice from "./cultureSlice";
import varieteSlice from "./varieteSlice";
import typePieceSlice from "./typePieceSlice";
import storage from 'redux-persist/lib/storage';
import persistStore from "redux-persist/es/persistStore";
import { persistReducer } from "redux-persist";
import regionSlice from "./regionSlice";
import prefectureSlice from "./prefectureSlice";
import sousPrefectureSlice from "./sousPrefectureSlice";
import zoneSlice from "./zoneSlice";
import sectionSlice from "./sectionSlice";
import faitiereSlice from "./faitiereSlice";
import ethnieSlice from "./ethnieSlice";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth','masterData'] // Only persist auth state
};
const modules = [masterDataModule, producteursSlice, authModule, userModule, cooperativesSlice, campagneSlice,districtSlice, cultureSlice, typePieceSlice, varieteSlice, regionSlice, prefectureSlice, zoneSlice,sousPrefectureSlice, sectionSlice, faitiereSlice, cooperativesSlice,ethnieSlice];
const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga(modules));

export { store, persistor };
