import { createModule } from "saga-slice";
import { loginApi } from "../services/authService";
import { call, put, race, take } from "redux-saga/effects";
import { masterDataActions } from "./dataSlice";

const authModule = createModule({
  name: "auth",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    fullName: null,
    role: null,
    error: null,
    logoutMessage: null,
  },
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
      state.logoutMessage = null;
    },
    loginSuccess: (state, payload) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.fullName = payload.fullName;
      state.role = payload.role;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.fullName = null;
      state.role = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.fullName = null;
      state.role = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      state.logoutMessage = "You have been logged out successfully.";
    },
  },
  sagas: (A) => ({
    *[A.loginRequest]({ payload }) {
      try {
        const data = yield call(loginApi, {
          username: payload.username,
          password: payload.password,
        });
        const { accessToken, refreshToken, fullName, role } = data;

        if (role === "ROLE_ADMIN") {
          yield put(
            A.loginSuccess({ accessToken, refreshToken, fullName, role })
          );

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);

          yield put(masterDataActions.loadMasterDataRequest());

          // Wait for master data success or failure
          const result = yield race({
            success: take(masterDataActions.loadMasterDataSuccess),
            failure: take(masterDataActions.loadMasterDataFailure),
          });

          if (result.success) {
            payload.navigate("/dashboard");
          } else {
            console.error("Failed to load master data.");
            yield put(
              A.loginFailure("Failed to load master data. Please try again.")
            );
          }
        } else {
          yield put(A.loginFailure("Only admins are allowed to log in."));
        }
      } catch (error) {
        yield put(A.loginFailure(error.message || "Something went wrong"));
      }
    },
  }),
});

export default authModule;
export const authActions = authModule.actions;
