import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AppRoute = (props) => {
  const { isAuthenticated, role } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: props.location }} />;
  }

  if (role !== "ROLE_ADMIN") {
    return <Navigate to="/unauthorized" state={{ from: props.location }} />;
  }

  return <>{props.children}</>;
};

export default AppRoute;
