import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { ethnieActions } from '../../../store/ethnieSlice';
import * as XLSX from 'xlsx';

const Ethnies = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        ethnies,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.ethnies);
    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedEthnie, setSelectedEthnie] = useState(null);
    const [formData, setFormData] = useState({
        code: '',
        description: '',
        actif: 'Y'
    });

    useEffect(() => {
        dispatch(ethnieActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage, dispatch]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                code: '',
                description: '',
                actif: 'Y',
            });
            setSelectedEthnie(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('ethnie.export.loading'),
            text: t('ethnie.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(ethnie => ({
                    'Code': ethnie.code,
                    'Description': ethnie.description,
                    'Statut': ethnie.actif === 'Y' ? 'Actif' : 'Inactif'
                }));
    
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Ethnies');
    
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
    
                const fileName = `ethnies_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
    
                Swal.fire({
                    title: t('ethnie.export.successTitle'),
                    text: t('ethnie.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('ethnie.export.errorTitle'),
                    text: t('ethnie.export.errorText'),
                    icon: 'error'
                });
            }
        };
    
        dispatch(ethnieActions.findAll(payload));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedEthnie?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedEthnie ? 'culture.update.successTitle' : 'culture.create.successTitle'),
                    text: t(selectedEthnie ? 'culture.update.successText' : 'culture.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(ethnieActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedEthnie ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
                    text: error.message || t(selectedEthnie ? 'culture.update.errorText' : 'culture.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedEthnie ?
            ethnieActions.update(payload) :
            ethnieActions.create(payload)
        );
    };

    const handleEdit = (ethnie) => {
        setSelectedEthnie(ethnie);
        setFormData({
            code: ethnie.code,
            description: ethnie.description,
            actif: ethnie.actif
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('ethnie.delete.confirmTitle'),
            text: t('ethnie.delete.confirmText'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('ethnie.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(ethnieActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('ethnie.delete.successTitle'),
                            text: t('ethnie.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(ethnieActions.search({ page: 0, size: pageSize }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('ethnie.delete.errorTitle'),
                            text: error.message || t('ethnie.delete.errorText'),
                            icon: 'error'
                        });
                    }

                }));
            }
        });
    };

    return (
        <Card>
            <CardBody>

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('ethnie.title')}</h4>
                        <p className="text-muted small mb-0">{t('ethnie.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('zone.new')}
                        </Button>
                    </div>
                </div>

                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('ethnie.code')}</th>
                                    <th>{t('ethnie.description')}</th>
                                    <th>{t('ethnie.status')}</th>
                                    <th>{t('ethnie.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ethnies && ethnies.map(ethnie => (
                                    <tr key={ethnie.id}>
                                        <td>{ethnie.code}</td>
                                        <td>{ethnie.description}</td>
                                        <td>
                                            <Badge color={ethnie.actif === 'Y' ? 'success' : 'secondary'}>
                                                {ethnie.actif === 'Y' ? t('ethnie.active') : t('ethnie.inactive')}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button color="info" size="sm" className="me-2"
                                                onClick={() => handleEdit(ethnie)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm"
                                                onClick={() => handleDelete(ethnie.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSize + 1,
                                end: Math.min((currentPage + 1) * pageSize, totalElements),
                                total: totalElements,
                                currentPage: currentPage,
                                totalPages: totalPages
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {selectedEthnie ? t('ethnie.edit') : t('ethnie.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="code">{t('ethnie.code')}</Label>
                                <Input
                                    id="code"
                                    name="code"
                                    value={formData.code}
                                    onChange={e => setFormData({ ...formData, code: e.target.value })}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">{t('ethnie.description')}</Label>
                                <Input
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={e => setFormData({ ...formData, description: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="actif">{t('ethnie.status')}</Label>
                                <Input
                                    type="select"
                                    id="actif"
                                    name="actif"
                                    value={formData.actif}
                                    onChange={e => setFormData({ ...formData, actif: e.target.value })}
                                >
                                    <option value="Y">{t('ethnie.active')}</option>
                                    <option value="N">{t('ethnie.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('common.cancel')}</Button>
                            <Button color="primary" type="submit">{t('common.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>

            </CardBody>
        </Card>
    );
};

export default Ethnies;