import React, { useEffect, useState } from "react";
import { Formik, Field, useFormik } from "formik";
import {
    Button,
    FormGroup,
    Label,
    Container,
    Input,
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
    CardHeader,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userActions } from "../../store/userSlice";
import {
    AvField,
    AvForm,
    AvRadio,
    AvRadioGroup,
} from "availity-reactstrap-validation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FaBox } from "react-icons/fa";

const MySwal = withReactContent(Swal);

const UserForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const isEdit = Boolean(id);
    const { t } = useTranslation("translation");
    const [filteredRegions, setFilteredRegions] = useState([]);
    const { isFetching, users } = useSelector((state) => state.user);
    const masterData = useSelector((state) => state.masterData.data);
    console.log(masterData);
    const breadcrumbItems = [
        { title: t("UserMain"), link: "/gestion-utilisateurs" },
        { title: t("UserForm"), link: "#", active: true },
    ];
    const [showPassword, setShowPassword] = useState(isEdit ? false : true);

    const [initialValues, setInitialValues] = useState({
        nom: "",
        prenom: "",
        username: "",
        email: "",
        tel: "",
        password: "",
        plainPassword: "",
        roles: "",
        enabled: true,
        districtId: "",
        districtCode: "",
        regionId: "",
        zoneId: "",
    });
    const formik = useFormik({
        initialValues: { ...initialValues },
        enableReinitialize: true,

        onSubmit: (values) => {
            console.log(values);
            const rolesArray = isEdit ? values.roles : [values.roles];
            const updatedValues = { ...values, roles: rolesArray };
            let payload = {
                data: updatedValues,
                onSuccess: (data) => {
                    Swal.fire({
                        icon: "success",
                        title: "Opération réussie",
                        text: "Les données ont été enregistrés avec succès.",
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/gestion-utilisateurs");
                        } else if (result.isDismissed) {
                        }
                    });
                },
            };

            if (isEdit) {
                dispatch(userActions.updateUser(payload));
            } else {
                dispatch(userActions.createUser(payload));
            }
        },
    });
    const { values, handleChange } = formik;
    console.log(formik.values);
    useEffect(() => {
        if (isEdit) {
            const user = users.find((u) => u.id === Number(id));
            if (user) {
                setInitialValues(user);
            }
        }
        if (formik.values.districtId) {
            const districtId = parseInt(formik.values.districtId, 10);
            const filtered = masterData.regions.filter(
                (region) =>
                    region.codeParent ===
                    masterData.districts.find((d) => d.id === districtId)?.code
            );

            setFilteredRegions(filtered);
            console.log(filteredRegions);
        } else {
            setFilteredRegions(masterData.regions);
        }
    }, [dispatch, id, isEdit, users, formik.values.districtId, masterData]);

    const handleGeneratePassword = () => {
        const randomPassword = generateRandomPassword();
        formik.setFieldValue("password", randomPassword); // Update formik field value
    };

    const generateRandomPassword = () => {
        const chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
        let password = "";
        for (let i = 0; i < 12; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };

    return (
        <React.Fragment>
            {isFetching ? (
                <div id="preloader">
                    <div id="status">
                        <div className="spinner">
                            <i className="ri-loader-line spin-icon"></i>
                        </div>
                    </div>
                </div>
            ) : (
                <Container fluid>
                    <Row className="mb-3">
                        <Col>
                            <Card className="shadow-sm border-0">
                                <CardBody className="py-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        {/* Left side - Title */}
                                        <h4 className="mb-0 d-flex align-items-center">
                                            <FaBox className="text-primary me-2" />
                                            {t('users.fiche')}
                                        </h4>

                                        {/* Right side - Breadcrumb */}
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0">
                                                <li className="breadcrumb-item">
                                                    <a href="/gestion-utilisateurs" className="text-decoration-none">
                                                        {t('users.main')}
                                                    </a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="currentPage">
                                                    {t('users.fiche')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <AvForm
                                className="needs-validation"
                                onValidSubmit={formik.handleSubmit}
                            >
                                <Card>
                                    <CardHeader className="d-flex justify-content-between align-items-center">
                                        <h5>
                                            {isEdit ? `N° ${formik.values.code}` : t("users.add")}
                                        </h5>
                                        {/* <Button color="success">
                                            <i className="ri-send-plane-fill"></i>{" "}
                                            {t("common.send")}
                                        </Button> */}
                                    </CardHeader>

                                    <CardBody>
                                        <Row>
                                            <Col md={4}>
                                                <AvField
                                                    type="select"
                                                    name="districtId"
                                                    label={t("District")}
                                                    {...formik.getFieldProps("districtId")}
                                                >
                                                    <option value="">{t("Select")}</option>
                                                    {masterData.districts.map((district) => (
                                                        <option key={district.id} value={district.id}>
                                                            {district.description}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    type="select"
                                                    name="regionId"
                                                    label={t("Region")}
                                                    {...formik.getFieldProps("regionId")}
                                                >
                                                    <option value="">{t("Select")}</option>
                                                    {filteredRegions.map((region) => (
                                                        <option key={region.id} value={region.id}>
                                                            {region.description}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    type="select"
                                                    name="zoneId"
                                                    label={t("Zone")}
                                                    {...formik.getFieldProps("zoneId")}
                                                >
                                                    <option value="">{t("Select")}</option>
                                                    {masterData.zones.map((zone) => (
                                                        <option key={zone.id} value={zone.id}>
                                                            {zone.description}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <AvField
                                                    name="nom"
                                                    label={t("Nom")}
                                                    type="text"
                                                    {...formik.getFieldProps("nom")}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <AvField
                                                    name="prenom"
                                                    label={t("Prenom")}
                                                    type="text"
                                                    {...formik.getFieldProps("prenom")}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <AvField
                                                    name="username"
                                                    label={t("Username")}
                                                    type="text"
                                                    {...formik.getFieldProps("username")}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <AvField
                                                    name="email"
                                                    label={t("Email")}
                                                    type="email"
                                                    {...formik.getFieldProps("email")}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3}>
                                                <AvField
                                                    name="tel"
                                                    label={t("Tel")}
                                                    type="text"
                                                    {...formik.getFieldProps("tel")}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <div className="position-relative">
                                                    {!isEdit ? <AvField
                                                        name="password"
                                                        label={t("Password")}
                                                        type={showPassword ? "text" : "password"}
                                                        {...formik.getFieldProps("password")}
                                                    /> : <AvField
                                                        name="plainPassword"
                                                        label={t("Password")}
                                                        type={showPassword ? "text" : "password"}
                                                        {...formik.getFieldProps("plainPassword")}
                                                    />
                                                    }

                                                    {/* Container for Buttons */}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "35px",
                                                            right: "10px",
                                                            display: "flex",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        {/* Show/Hide Password Button */}
                                                        <Button
                                                            color="link"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            style={{
                                                                padding: 0,
                                                                height: "fit-content",
                                                                lineHeight: "1",
                                                            }}
                                                        >
                                                            <i
                                                                className={
                                                                    showPassword
                                                                        ? "ri-eye-off-line"
                                                                        : "ri-eye-line"
                                                                }
                                                            ></i>
                                                        </Button>

                                                        {/* Generate Password Button */}
                                                        <Button
                                                            color="link"
                                                            onClick={handleGeneratePassword}
                                                            style={{
                                                                padding: 0,
                                                                height: "fit-content",
                                                                lineHeight: "1",
                                                            }}
                                                        >
                                                            <i className="ri-refresh-line"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <AvField
                                                    type="select"
                                                    name="roles"
                                                    label={t("Roles")}
                                                    {...formik.getFieldProps("roles")}
                                                >
                                                    <option value="">{t("Select")}</option>
                                                    <option value="ROLE_ADMIN">Admin</option>
                                                    <option value="ROLE_AGENT">Agent</option>
                                                </AvField>
                                            </Col>
                                            <Col md={3}>
                                                <div className="form-switch d-flex align-items-center">
                                                    <label htmlFor="enabledSwitch" className="me-2">
                                                        {t("Enabled")}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="enabledSwitch"
                                                        name="enabled"
                                                        className="form-switch-input"
                                                        {...formik.getFieldProps("enabled")}
                                                        checked={formik.values.enabled}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="d-flex justify-content-between">
                                        <Button
                                            onClick={() => navigate("/gestion-utilisateurs")}
                                            color="danger"
                                        >
                                            {t("common.cancel")}
                                        </Button>
                                        <Button type="submit" color="primary">
                                            {t("common.save")}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>
            )}
        </React.Fragment>
    );
};

export default UserForm;
