import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaMapMarkerAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { zoneActions } from '../../../store/zoneSlice';
import { sectionActions } from '../../../store/sectionSlice';

const Sections = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        zones,
        isFetching: isFetchingZones,
        totalPages: totalPagesZones,
        totalElements,
        pageNumber: pageNumberZones,
        pageSize: pageSizeZones,
    } = useSelector((state) => state.zones);
    const { sections, isFetching, totalPages, pageNumber, pageSize } = useSelector(state => state.sections);
    const [zoneModal, setZoneModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);
    const [formData, setFormData] = useState({
        codeSection: '',
        nomSection: '',
        actif: 'Y',
        coordonneesGps: '',
        codeZone: '',
        nomZone: '',
        matriculeSuperviseurSection: '',
        nomSuperviseurSection: '',
        contact: '',
        email: '',
        lieuResidence: '',
        listeDesLocalitesEnCharge: []
    });

    useEffect(() => {
        dispatch(sectionActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage, dispatch]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                codeSection: '',
                nomSection: '',
                actif: 'Y',
                coordonneesGps: '',
                codeZone: '',
                nomZone: '',
                matriculeSuperviseurSection: '',
                nomSuperviseurSection: '',
                contact: '',
                email: '',
                lieuResidence: '',
                listeDesLocalitesEnCharge: []
            });
            setSelectedSection(null);
        }
    };


    const toggleZoneModal = () => {
        setZoneModal(!zoneModal);
        if (!zoneModal) {
            dispatch(zoneActions.search());
        }
    };

    const handleZoneSelect = (zone) => {
        setFormData({
            ...formData,
            codeZone: zone.codeZone,
            nomZone: zone.nomZone
        });
        toggleZoneModal();
    };

    const handleLocalityAdd = () => {
        const locality = prompt(t('section.enterLocality'));
        if (locality) {
            setFormData({
                ...formData,
                listeDesLocalitesEnCharge: [...formData.listeDesLocalitesEnCharge, locality]
            });
        }
    };

    const handleLocalityRemove = (index) => {
        setFormData({
            ...formData,
            listeDesLocalitesEnCharge: formData.listeDesLocalitesEnCharge.filter((_, i) => i !== index)
        });
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedSection?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedSection ? 'section.update.successTitle' : 'section.create.successTitle'),
                    text: t(selectedSection ? 'section.update.successText' : 'section.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(sectionActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedSection ? 'section.update.errorTitle' : 'section.create.errorTitle'),
                    text: error.message || t(selectedSection ? 'section.update.errorText' : 'section.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedSection ?
            sectionActions.update(payload) :
            sectionActions.create(payload)
        );
    };

    const handleEdit = (section) => {
        setSelectedSection(section);
        setFormData({
            codeSection: section.codeSection,
            nomSection: section.nomSection,
            actif: section.actif,
            coordonneesGps: section.coordonneesGps,
            codeZone: section.codeZone,
            nomZone: section.nomZone,
            matriculeSuperviseurSection: section.matriculeSuperviseurSection,
            nomSuperviseurSection: section.nomSuperviseurSection,
            contact: section.contact,
            email: section.email,
            lieuResidence: section.lieuResidence,
            listeDesLocalitesEnCharge: section.listeDesLocalitesEnCharge
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('section.delete.confirmTitle'),
            text: t('section.delete.confirmText'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('section.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(sectionActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('section.delete.successTitle'),
                            text: t('section.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(sectionActions.search({ page: 0, size: pageSize }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('section.delete.errorTitle'),
                            text: error.message || t('section.delete.errorText'),
                            icon: 'error'
                        });
                    }
                }));
            }
        });
    };

    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('section.title')}</h4>
                        <p className="text-muted small mb-0">{t('section.description')}</p>
                    </div>
                    <Button color="primary" onClick={toggle}>
                        <FaPlus className="me-2" /> {t('section.new')}
                    </Button>
                </div>

                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('section.code')}</th>
                                    <th>{t('section.name')}</th>
                                    <th>{t('section.supervisor.id')}</th>
                                    <th>{t('section.zone')}</th>
                                    <th>{t('section.contact')}</th>
                                    <th>{t('section.coordinates')}</th>
                                    <th>{t('section.status')}</th>
                                    <th>{t('section.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sections && sections.map(section => (
                                    <tr key={section.id}>
                                        <td>{section.codeSection}</td>
                                        <td>{section.nomSection}</td>
                                        <td>
                                            <div>{section.nomSuperviseurSection}</div>
                                            <small className="text-muted">{section.matriculeSuperviseurSection}</small>
                                        </td>
                                        <td>{section.nomZone}</td>
                                        <td>
                                            <div>{section.contact}</div>
                                            <small>{section.email}</small>
                                        </td>
                                        <td>{section.coordonneesGps}</td>
                                        <td>
                                            <Badge color={section.actif === 'Y' ? 'success' : 'secondary'}>
                                                {section.actif === 'Y' ? t('section.active') : t('section.inactive')}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button color="info" size="sm" className="me-2"
                                                onClick={() => handleEdit(section)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm"
                                                onClick={() => handleDelete(section.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSize + 1,
                                end: Math.min((currentPage + 1) * pageSize, totalElements),
                                total: totalElements,
                                currentPage: currentPage,
                                totalPages: totalPages
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {selectedSection ? t('section.edit') : t('section.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label>{t('section.zone')}</Label>
                                <div className="d-flex gap-2">
                                    <Input
                                        value={formData.nomZone}
                                        readOnly
                                        placeholder={t('section.selectZone')}
                                    />
                                    <Button color="secondary" onClick={toggleZoneModal}>
                                        <FaMapMarkerAlt />
                                    </Button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="codeSection">{t('section.code')}</Label>
                                <Input
                                    id="codeSection"
                                    name="codeSection"
                                    value={formData.codeSection}
                                    onChange={e => setFormData({ ...formData, codeSection: e.target.value })}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomSection">{t('section.name')}</Label>
                                <Input
                                    id="nomSection"
                                    name="nomSection"
                                    value={formData.nomSection}
                                    onChange={e => setFormData({ ...formData, nomSection: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="matriculeSuperviseurSection">{t('section.supervisor.id')}</Label>
                                <Input
                                    id="matriculeSuperviseurSection"
                                    name="matriculeSuperviseurSection"
                                    value={formData.matriculeSuperviseurSection}
                                    onChange={e => setFormData({ ...formData, matriculeSuperviseurSection: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomSuperviseurSection">{t('section.supervisor.name')}</Label>
                                <Input
                                    id="nomSuperviseurSection"
                                    name="nomSuperviseurSection"
                                    value={formData.nomSuperviseurSection}
                                    onChange={e => setFormData({ ...formData, nomSuperviseurSection: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="contact">{t('section.contact')}</Label>
                                <Input
                                    id="contact"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={e => setFormData({ ...formData, contact: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">{t('section.email')}</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={e => setFormData({ ...formData, email: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="coordonneesGps">{t('section.coordinates')}</Label>
                                <Input
                                    id="coordonneesGps"
                                    name="coordonneesGps"
                                    value={formData.coordonneesGps}
                                    onChange={e => setFormData({ ...formData, coordonneesGps: e.target.value })}
                                    placeholder="Lat, Long"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="active">{t('section.status')}</Label>
                                <Input
                                    type="select"
                                    id="active"
                                    name="actif"
                                    value={formData.actif}
                                    onChange={e => setFormData({ ...formData, actif: e.target.value })}
                                >
                                    <option value="Y">{t('section.active')}</option>
                                    <option value="N">{t('section.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('section.cancel')}</Button>
                            <Button color="primary" type="submit">{t('section.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </CardBody>
            <Modal isOpen={zoneModal} toggle={toggleZoneModal} size="lg">
                <ModalHeader toggle={toggleZoneModal}>
                    {t('zone.select')}
                </ModalHeader>
                <ModalBody>
                    {isFetchingZones ? (
                        <div className="text-center py-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">{t('common.loading')}</span>
                            </div>
                            <div className="mt-2 text-muted">
                                {t('common.loadingData')}
                            </div>
                        </div>
                    ) : (
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('zone.code')}</th>
                                    <th>{t('zone.name')}</th>
                                    <th>{t('zone.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {zones.map(zone => (
                                    <tr key={zone.id}>
                                        <td>{zone.codeZone}</td>
                                        <td>{zone.nomZone}</td>
                                        <td>
                                            <Button color="primary" size="sm" onClick={() => handleZoneSelect(zone)}>
                                                {t('zone.select')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleZoneModal}>{t('zone.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </Card>
    );
};

export default Sections;