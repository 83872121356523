import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { producteursActions } from "../../store/producteursSlice";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles.css";
import { useTranslation } from "react-i18next";
import { FaBox } from "react-icons/fa";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    superficieCulture: [],
    byAgeGroup: [],
    byOrigine: [],
    byGenre: [],
    byZone: [],
    byDistrict: [],
    byRegion: [],
  });
  const masterData = useSelector((state) => state.masterData.data);
  const [campagneId, setCampagneId] = useState(masterData?.campagnes[0]?.id);
  const { t } = useTranslation();
  useEffect(() => {
    const payload = {
      campagneId,
      onSuccess: (data) => {
        setData(data);
      },
      onError: (error) => {
        console.log(error);
      },
    };

    dispatch(producteursActions.dashboard(payload));
  }, [campagneId, dispatch]);

  const getChartData = (data, nameKey, countKey) => {
    return data?.map((item) => ({
      name: item[nameKey],
      count: item[countKey],
    }));
  };

  const GENRE_COLORS = {
    Homme: "#FF7F3E",
    Femme: "#001A6E",
  };
  const ORIGINE_COLORS = {
    Autochtone: "#FF7F3E",
    Allochtone: "#001A6E",
    Allogène: "#5CB338",
  };
  const AGE_COLORS = {
    "Moins de 35 ans": "#3D0301",
    "De 35 a 54 ans": "#E73879",
    "Plus de 55 ans": "#31511E",
  };

  const colorPalette = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F333FF",
    "#FF33A5",
    "#FFC300",
    "#DAF7A6",
    "#581845",
    "#900C3F",
    "#C70039",
    "#FF5733",
    "#FF8D1A",
    "#33C1FF",
  ];
  const assignColors = (dataArray) => {
    const colorMap = {};
    let colorIndex = 0;

    dataArray.forEach((item) => {
      colorMap[item.description] =
        colorPalette[colorIndex % colorPalette.length]; // Loop through the palette
      colorIndex++;
    });

    return colorMap;
  };
  const REGION_COLORS = assignColors(masterData.regions);
  const ZONE_COLORS = assignColors(masterData.zones);
  const DISTRICT_COLORS = assignColors(masterData.districts);

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <Card className="shadow-sm border-0">
            <CardBody className="py-3">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left side - Title */}
                <h4 className="mb-0 d-flex align-items-center">
                  <FaBox className="text-primary me-2" />
                  {t('dashboard.pageTitle')}
                </h4>

                {/* Right side - Breadcrumb */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard" className="text-decoration-none">
                        {t('nav.dashboard')}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t('nav.dashboard')}
                    </li>
                  </ol>
                </nav>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pe-4">
          <Card className="shadow-sm h-100">
            <CardBody>
              <Row>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 text-center">
                    <h3 className="m-0">Statistiques des Producteurs</h3>
                  </div>
                  <select
                    name="campagneId"
                    value={campagneId}
                    onChange={(e) => setCampagneId(e.target.value)}
                    required
                    className="ml-3 custom-select"
                  >
                    {masterData.campagnes.map((campagne) => (
                      <option key={campagne.id} value={campagne.id}>
                        Campagne : {campagne.description}
                      </option>
                    ))}
                  </select>
                </div>
              </Row>

              <div className="p-3">
                <Row>
                  <Col className="box">
                    <h5>Genre</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          // data={getChartData(data?.byGenre, "key", "count")}
                          data={data.byGenre}
                          dataKey="count"
                          nameKey="key"
                          cx="40%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byGenre.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byGenre.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={GENRE_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="left"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col className="box">
                    <h5> Origine</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={data.byOrigine}
                          dataKey="count"
                          nameKey="key"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byOrigine.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byOrigine.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={ORIGINE_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="lett"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col className="box">
                    <h5>Classe d'age</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={data.byAgeGroup}
                          dataKey="count"
                          nameKey="key"
                          cx="30%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byAgeGroup.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byAgeGroup.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={AGE_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="left"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
                <Row>
                  <Col className="box">
                    <h5> Region</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={data.byRegion}
                          dataKey="count"
                          nameKey="key"
                          cx="40%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byRegion.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byRegion.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={REGION_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="left"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col className="box">
                    <h5> District</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={data.byDistrict}
                          dataKey="count"
                          nameKey="key"
                          cx="30%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byDistrict.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byDistrict.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={DISTRICT_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="left"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col className="box">
                    <h5> Zone</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={data.byZone}
                          dataKey="count"
                          nameKey="key"
                          cx="40%"
                          cy="50%"
                          outerRadius={100}
                          label={({ count }) => {
                            const total = data.byZone.reduce(
                              (sum, entry) => sum + entry.count,
                              0
                            );
                            const percentage = ((count / total) * 100).toFixed(1);
                            return `${count} (${percentage}%)`;
                          }}
                        >
                          {data?.byZone.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={ZONE_COLORS[entry.key]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="right"
                          align="left"
                        />{" "}
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="p-2 d-flex align-items-center mb-3">
                <h3>Superficies Cultures Agricoles</h3>
              </Row>
              <Row>
                <div className="chart">
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                      data={getChartData(
                        data?.superficieCulture,
                        "key",
                        "superficie"
                      )}
                      margin={{ top: 50 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="count" fill="#8884d8" barSize={30}>
                        {" "}
                        <LabelList
                          dataKey="count"
                          position="top"
                          content={({ x, y, value }) => {
                            return (
                              <text
                                x={x}
                                y={y}
                                dy={-6}
                                fill="#000"
                                textAnchor="middle"
                              >
                                {`${value} ha`}
                              </text>
                            );
                          }}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
