import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';

import { FaPlus, FaEdit, FaTrash, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { zoneActions } from '../../../store/zoneSlice';
import * as XLSX from 'xlsx';

const Zones = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        zones,
        isFetching,
        totalPages,
        totalElements,
        pageNumber,
        pageSize,
        error,
    } = useSelector((state) => state.zones);
    const [currentPage, setCurrentPage] = useState(pageNumber); // Track current page
    const [modal, setModal] = useState(false);
    const [selectedZone, setSelectedZone] = useState(null);
    const [formData, setFormData] = useState({
        codeZone: '',
        nomZone: '',
        actif: 'Y',
        coordonneesGps: '',
        matriculeChefZone: '',
        nomChefZone: '',
        contact: '',
        email: ''
    });

    useEffect(() => {
        dispatch(zoneActions.search({ page: currentPage, size: pageSize }));
    }, [currentPage, dispatch]);

    const toggle = () => {
        setModal(!modal);
        if (modal) {
            setFormData({
                codeZone: '',
                nomZone: '',
                actif: 'Y',
                coordonneesGps: '',
                matriculeChefZone: '',
                nomChefZone: '',
                contact: '',
                email: ''
            });
            setSelectedZone(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            dto: formData,
            id: selectedZone?.id,
            onSuccess: () => {
                toggle();
                Swal.fire({
                    title: t(selectedZone ? 'culture.update.successTitle' : 'culture.create.successTitle'),
                    text: t(selectedZone ? 'culture.update.successText' : 'culture.create.successText'),
                    icon: 'success',
                    timer: 1500
                });
                dispatch(zoneActions.search({ page: 0, size: pageSize }));
            },
            onError: (error) => {
                Swal.fire({
                    title: t(selectedZone ? 'culture.update.errorTitle' : 'culture.create.errorTitle'),
                    text: error.message || t(selectedZone ? 'culture.update.errorText' : 'culture.create.errorText'),
                    icon: 'error'
                });
            }
        };

        dispatch(selectedZone ?
            zoneActions.update(payload) :
            zoneActions.create(payload)
        );
    };

    const handleExport = async () => {
        Swal.fire({
            title: t('zone.export.loading'),
            text: t('zone.export.wait'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    
        let payload = {
            onSuccess: (data) => {
                const excelData = data.map(zone => ({
                    'Code Zone': zone.codeZone,
                    'Nom Zone': zone.nomZone,
                    'Statut': zone.actif === 'Y' ? 'Actif' : 'Inactif',
                    'Coordonnées GPS': zone.coordonneesGps || '',
                    'Matricule Chef Zone': zone.matriculeChefZone || '',
                    'Nom Chef Zone': zone.nomChefZone || '',
                    'Contact': zone.contact || '',
                    'Email': zone.email || ''
                }));
    
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Zones');
    
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
    
                const fileName = `zones_${new Date().toISOString().split('T')[0]}.xlsx`;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
    
                Swal.fire({
                    title: t('zone.export.successTitle'),
                    text: t('zone.export.successText'),
                    icon: 'success',
                    timer: 1500
                });
            },
            onError: (error) => {
                Swal.fire({
                    title: t('zone.export.errorTitle'),
                    text: t('zone.export.errorText'),
                    icon: 'error'
                });
            }
        };
    
        dispatch(zoneActions.findAll(payload));
    };

    const handleEdit = (zone) => {
        setSelectedZone(zone);
        setFormData({
            codeZone: zone.codeZone,
            nomZone: zone.nomZone,
            actif: zone.actif,
            coordonneesGps: zone.coordonneesGps,
            matriculeChefZone: zone.matriculeChefZone,
            nomChefZone: zone.nomChefZone,
            contact: zone.contact,
            email: zone.email
        });
        toggle();
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t('culture.delete.title'),
            text: t('culture.delete.text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('culture.delete.confirm')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(zoneActions.delete({
                    id,
                    onSuccess: () => {
                        Swal.fire({
                            title: t('culture.delete.successTitle'),
                            text: t('culture.delete.successText'),
                            icon: 'success',
                            timer: 1500
                        });
                        dispatch(zoneActions.search({ page: 0, size: pageSize }))
                    },
                    onError: (error) => {
                        Swal.fire({
                            title: t('culture.delete.errorTitle'),
                            text: error.message || t('culture.delete.errorText'),
                            icon: 'error'
                        });
                    }

                }));
            }
        });
    };

    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h4 className="card-title mb-1">{t('zone.title')}</h4>
                        <p className="text-muted small mb-0">{t('zone.description')}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="success" className="d-flex align-items-center" onClick={handleExport}>
                            <FaFileExcel className="me-2" /> {t('common.download')}
                        </Button>
                        <Button color="primary" className="d-flex align-items-center" onClick={toggle}>
                            <FaPlus className="me-2" /> {t('zone.new')}
                        </Button>
                    </div>
                </div>


                {isFetching ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                        <div className="mt-2 text-muted">
                            {t('common.loadingData')}
                        </div>
                    </div>
                ) : (<>
                    <div className="table-responsive">
                        <Table hover bordered className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t('zone.code')}</th>
                                    <th>{t('zone.name')}</th>
                                    <th>{t('zone.chiefId')}</th>
                                    <th>{t('zone.contact')}</th>
                                    <th>{t('zone.coordinates')}</th>
                                    <th>{t('zone.status')}</th>
                                    <th>{t('zone.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {zones && zones.map(zone => (
                                    <tr key={zone.id}>
                                        <td>{zone.codeZone}</td>
                                        <td>{zone.nomZone}</td>
                                        <td>
                                            <div>{zone.nomChefZone}</div>
                                            <small className="text-muted">{zone.matriculeChefZone}</small>
                                        </td>
                                        <td>
                                            <div>{zone.contact}</div>
                                            <small>{zone.email}</small>
                                        </td>
                                        <td>{zone.coordonneesGps}</td>
                                        <td>
                                            <Badge color={zone.actif === 'Y' ? 'success' : 'secondary'}>
                                                {zone.actif === 'Y' ? t('zone.active') : t('zone.inactive')}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Button color="info" size="sm" className="me-2"
                                                onClick={() => handleEdit(zone)}>
                                                <FaEdit />
                                            </Button>
                                            <Button color="danger" size="sm"
                                                onClick={() => handleDelete(zone.id)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            {t('actions.showing', {
                                start: (currentPage) * pageSize + 1,
                                end: Math.min((currentPage + 1) * pageSize, totalElements),
                                total: totalElements,
                                currentPage: currentPage,
                                totalPages: totalPages
                            })}
                        </small>
                        <Pagination size="sm" className="mb-0">
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink first onClick={() => handlePageChange(0)}>
                                    <FaAngleDoubleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 0}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaAngleLeft size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaAngleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === totalPages - 1}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages - 1)}>
                                    <FaAngleDoubleRight size={12} />
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </>
                )}

                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>
                        {selectedZone ? t('zone.edit') : t('zone.new')}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="codeZone">{t('zone.code')}</Label>
                                <Input
                                    id="codeZone"
                                    name="codeZone"
                                    value={formData.codeZone}
                                    onChange={e => setFormData({ ...formData, codeZone: e.target.value })}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomZone">{t('zone.name')}</Label>
                                <Input
                                    id="nomZone"
                                    name="nomZone"
                                    value={formData.nomZone}
                                    onChange={e => setFormData({ ...formData, nomZone: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="matriculeChefZone">{t('zone.chiefId')}</Label>
                                <Input
                                    id="matriculeChefZone"
                                    name="matriculeChefZone"
                                    value={formData.matriculeChefZone}
                                    onChange={e => setFormData({ ...formData, matriculeChefZone: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="nomChefZone">{t('zone.chiefName')}</Label>
                                <Input
                                    id="nomChefZone"
                                    name="nomChefZone"
                                    value={formData.nomChefZone}
                                    onChange={e => setFormData({ ...formData, nomChefZone: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="contact">{t('zone.contact')}</Label>
                                <Input
                                    id="contact"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={e => setFormData({ ...formData, contact: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">{t('zone.email')}</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={e => setFormData({ ...formData, email: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="coordonneesGps">{t('zone.coordinates')}</Label>
                                <Input
                                    id="coordonneesGps"
                                    name="coordonneesGps"
                                    value={formData.coordonneesGps}
                                    onChange={e => setFormData({ ...formData, coordonneesGps: e.target.value })}
                                    placeholder="Lat, Long"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="active">{t('zone.status')}</Label>
                                <Input
                                    type="select"
                                    id="active"
                                    name="actif"
                                    value={formData.actif}
                                    onChange={e => setFormData({ ...formData, actif: e.target.value })}
                                >
                                    <option value="Y">{t('zone.active')}</option>
                                    <option value="N">{t('zone.inactive')}</option>
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{t('zone.cancel')}</Button>
                            <Button color="primary" type="submit">{t('zone.save')}</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Zones;