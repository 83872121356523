import axiosInstance from "./api_helper";

const API_BASE_URL = "/data"; // Define the base URL for master data API
export const fetchMasterData = async () => {
  try {
    const response = await axiosInstance.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch master data");
  }
};

export const createDistrict = async (data) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/district`, data);
    return response.data;
  } catch (error) {
    throw new Error("Failed to create District");
  }
};
export const createRegion = async (data) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/region`, data);
    return response.data;
  } catch (error) {
    throw new Error("Failed to create Region");
  }
};
export const createPrefecture = async (data) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/prefecture`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create Prefecture");
  }
};
export const createSousPrefecture = async (data) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/sousPrefecture`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create SousPrefecture");
  }
};

export const deleteDistrict = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${API_BASE_URL}/district/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete District");
  }
};
export const deleteRegion = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_BASE_URL}/region/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete Region");
  }
};
export const deletePrefecture = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${API_BASE_URL}/prefecture/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete Prefecture");
  }
};
export const deleteSousPrefecture = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${API_BASE_URL}/sousPrefecture/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete SousPrefecture");
  }
};

export const updateDistrict = async (id, data) => {
  try {
    const response = await axiosInstance.put(
      `${API_BASE_URL}/district/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update District");
  }
};

export const updateRegion = async (id, data) => {
  try {
    const response = await axiosInstance.put(
      `${API_BASE_URL}/region/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update Region");
  }
};

export const updatePrefecture = async (id, data) => {
  try {
    const response = await axiosInstance.put(
      `${API_BASE_URL}/prefecture/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update Prefecture");
  }
};

export const updateSousPrefecture = async (id, data) => {
  try {
    const response = await axiosInstance.put(
      `${API_BASE_URL}/sousPrefecture/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update SousPrefecture");
  }
};
