import React, { useEffect, useState } from "react";
import { Formik, Field, useFormik } from "formik";
import {
  Button,
  FormGroup,
  Label,
  Container,
  Input,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter,
  CardHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { producteursActions } from "../../store/producteursSlice";
import classnames from "classnames";
import { FaBox, FaBoxes } from "react-icons/fa";

const MySwal = withReactContent(Swal);
const DEFAULT_COORDINATES = { latitude: 5.30966, longitude: -4.01266 }; // Abidjan

const ProducteurForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const { t } = useTranslation("translation");
  const {
    isFetching,
    totalPages,
    producteurs,
    totalElements,
    pageNumber,
    pageSize,
    error,
  } = useSelector((state) => state.producteurs);
  const masterData = useSelector((state) => state.masterData.data);
  console.log(masterData);
  const breadcrumbItems = [
    { title: t("producteur.main"), link: "/gestion-producteurs" },
    { title: t("producteur.fiche"), link: "#", active: true },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCultures, setSelectedCultures] = useState([]);

  // Update the cultures in Formik
  const handleCulturesSelection = (selected) => {
    setSelectedCultures(selected || []);
    console.log(selected);
    // Update Formik state for cultures
    formik.setFieldValue(
      "cultures",
      selected.map((culture) => ({
        cultureAgricoleId: culture.value,
        nomCulture: culture.label,
        nombreParcelles: 0,
        surfaceEnHectare: 0.0,
        productionEstimeeKg: 0.0,
        chiffreAffaireEstime: 0.0,
      }))
    );
  };
  const [initialValues, setInitialValues] = useState({
    campagneId: 0,
    codeProducteur: "",
    nomPrenoms: "",
    dateDebutPartenariat: "",
    genre: "",
    dateNaissance: "",
    lieuNaissance: "",
    nomPere: "",
    nomMere: "",
    numeroTelephone: "",
    numeroCmu: "",
    districtId: 0,
    regionId: 0,
    prefectureId: 0,
    sousPrefectureId: 0,
    zoneId: 0,
    faitiereId: 0,
    cooperativeId: 0,
    nationalite: "",
    typePieceIdentification: "",
    numeroPiece: "",
    dateEtablissementPiece: "",
    origine: "",
    ethnieId: 0,
    situationMatrimoniale: "",
    nombreFemmes: 0,
    nombreEnfants: 0,
    estAlleEcole: false,
    niveauEtude: "",
    cultures: [], // New field for dynamic cultures
    imageServerUrl: "",
    imageLocalUrl: "",
    localiste: 0,
  });

  const formatDate = (dateArray) => {
    if (!dateArray || dateArray.length !== 3) return ""; // Return an empty string if the date is invalid
    const [year, month, day] = dateArray;
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (isEdit) {
      const prod = producteurs.find((u) => u.id === Number(id));
      if (prod) {
        setInitialValues(prod);
      }
    }
  }, [dispatch, id, isEdit, producteurs]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      // dateNaissance: formatDate(initialValues?.dateNaissance),
      // dateDebutPartenariat: formatDate(initialValues?.dateDebutPartenariat),
      // dateEtablissementPiece: formatDate(initialValues?.dateEtablissementPiece),
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log(values);
      const rolesArray = isEdit ? values.roles : [values.roles];
      const updatedValues = { ...values, roles: rolesArray };
      let payload = {
        data: updatedValues,
        onSuccess: (data) => {
          Swal.fire({
            icon: "success",
            title: "Opération réussie",
            text: "Les données ont été enregistrés avec succès.",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/gestion-utilisateurs");
            } else if (result.isDismissed) {
            }
          });
        },
      };

      if (isEdit) {
        dispatch(producteursActions.updateProducteur(payload));
      } else {
        dispatch(producteursActions.createProducteur(payload));
      }
    },
  });

  const { values, handleChange } = formik;
  const coordinates = {
    latitude: formik.values?.latitude || DEFAULT_COORDINATES.latitude,
    longitude: formik.values?.longitude || DEFAULT_COORDINATES.longitude,
  };

  const [filteredSousPrefectures, setFilteredSousPrefectures] = useState([]);
  const [filteredVillages, setFilteredVillages] = useState([]);

  // Dropdown option formatter
  const formatOptions = (items, labelKey, valueKey = "id") =>
    items.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));


  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <Card className="shadow-sm border-0">
                <CardBody className="py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Left side - Title */}
                    <h4 className="mb-0 d-flex align-items-center">
                      <FaBox className="text-primary me-2" />
                      {t('producteur.fiche')}
                    </h4>

                    {/* Right side - Breadcrumb */}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <a href="/gestion-producteurs" className="text-decoration-none">
                            {t('producteur.main')}
                          </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="currentPage">
                          {t('producteur.fiche')}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm
            className="needs-validation"
            onValidSubmit={formik.handleSubmit}
          >
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-primary me-2" />
                  <h5 className="mb-0">{t("Informations Personnelles")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* Code Producteur (Read-only) */}
                  <Col md={3}>
                    <AvField
                      name="codeProducteur"
                      label="Code Producteur"
                      value={formik.values.codeProducteur} // Display the generated value
                      readOnly
                    />
                  </Col>

                  {/* Nom et Prénoms Producteur */}
                  <Col md={3}>
                    <AvField
                      name="nomPrenoms"
                      label="Nom et Prénoms Producteur"
                      type="text"
                      {...formik.getFieldProps("nomPrenoms")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Date de Naissance */}
                  <Col md={3}>
                    <AvField
                      name="dateNaissance"
                      label="Date de Naissance"
                      //type="date"
                      {...formik.getFieldProps("dateNaissance")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Lieu de Naissance */}
                  <Col md={3}>
                    <AvField
                      name="lieuNaissance"
                      label="Lieu de Naissance"
                      type="text"
                      {...formik.getFieldProps("lieuNaissance")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Nom et Prénoms Père */}
                  <Col md={3}>
                    <AvField
                      name="nomPere"
                      label="Nom et Prénoms Père"
                      type="text"
                      {...formik.getFieldProps("nomPere")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Nom et Prénoms Mère */}
                  <Col md={3}>
                    <AvField
                      name="nomMere"
                      label="Nom et Prénoms Mère"
                      type="text"
                      {...formik.getFieldProps("nomMere")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Genre Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="genre"
                      label="Genre"
                      {...formik.getFieldProps("genre")}
                      required
                      errorMessage="Champ obligatoire"
                    >
                      <option value="">Sélectionner</option>
                      <option value="HOMME">Homme</option>
                      <option value="FEMME">Femme</option>
                    </AvField>
                  </Col>

                  {/* N° de Téléphone */}
                  <Col md={3}>
                    <AvField
                      name="numeroTelephone"
                      label="N° de Téléphone"
                      type="text"
                      {...formik.getFieldProps("numeroTelephone")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* N° CMU */}
                  <Col md={3}>
                    <AvField
                      name="numeroCmu"
                      label="N° CMU"
                      type="text"
                      {...formik.getFieldProps("numeroCmu")}
                      required
                      errorMessage="Champ obligatoire"
                    />
                  </Col>

                  {/* Type de Pièce Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="typePieceIdentification"
                      label="Type de Pièce"
                      {...formik.getFieldProps("typePieceIdentification")}
                      required
                      errorMessage="Champ obligatoire"
                    >
                      <option value="">Sélectionner</option>
                      <option value="CNI">CNI</option>
                      <option value="PASSPORT">Passport</option>
                      <option value="SEJOUR">Carte de séjour</option>
                      <option value="EXTRAIT">Extrait de naissance</option>
                      <option value="CONSULAIRE">Carte consulaire</option>
                      <option value="AUCUN">Aucun</option>
                    </AvField>
                  </Col>

                  {/* N° Pièce d’identification */}
                  <Col md={3}>
                    {formik.values.typePieceIdentification !== "Aucun" && (
                      <AvField
                        name="numeroPiece"
                        label="N° Pièce d’identification"
                        type="text"
                        {...formik.getFieldProps("numeroPiece")}
                        required
                        errorMessage="Champ obligatoire"
                      />
                    )}
                  </Col>

                  {/* Date d’établissement */}
                  <Col md={3}>
                    {formik.values.typePieceIdentification !== "Aucun" && (
                      <AvField
                        name="dateEtablissementPiece"
                        label="Date d'établissement"
                        //type="date"
                        {...formik.getFieldProps("dateEtablissementPiece")}
                        required
                        errorMessage="Champ obligatoire"
                      />
                    )}
                  </Col>

                  {/* Ethnie Dropdown Search */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="ethnie"
                      label="Ethnie"
                      {...formik.getFieldProps("ethnie")}
                      required
                    >
                      <option value="">Sélectionner</option>
                      {masterData.ethnies.map((ethnie) => (
                        <option key={ethnie.id} value={ethnie.id}>
                          {ethnie.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Origine Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="origine"
                      label="Origine"
                      {...formik.getFieldProps("origine")}
                    >
                      <option value="">Sélectionner</option>
                      <option value="AUTHOCHTONE">Autochtone</option>
                      <option value="ALLOGENE">Allogène</option>
                      <option value="ALLOCHTONE">Allochtone</option>
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  {/* Situation Matrimoniale Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="situationMatrimoniale"
                      label="Situation Matrimoniale"
                      {...formik.getFieldProps("situationMatrimoniale")}
                      required
                    >
                      <option value="">Sélectionner</option>
                      <option value="CELEBATAIRE">Célibataire</option>
                      <option value="MARIE">Marié(e)</option>
                      <option value="DIVORCE">Divorcé(e)</option>
                      <option value="VEUF">Veuf(ve)</option>
                    </AvField>
                  </Col>

                  {/* Nationalité Dropdown Search */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="nationalite"
                      label="Nationalité"
                      {...formik.getFieldProps("nationalite")}
                      required
                    >
                      <option value="">Sélectionner</option>
                      {masterData.nationalities.map((item) => (
                        <option key={item.code} value={item.description}>
                          {item.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Nombre de Femmes - Number Field */}
                  <Col md={3}>
                    <AvField
                      name="nombreFemmes"
                      label="Nombre de Femmes"
                      value={formik.values.nombreFemmes} // Explicitly set the value
                      type="number"
                      {...formik.getFieldProps("nombreFemmes")}
                      required
                    />
                  </Col>

                  {/* Nombre d'Enfants - Number Field */}
                  <Col md={3}>
                    <AvField
                      name="nombreEnfants"
                      label="Nombre d'Enfants"
                      value={formik.values.nombreEnfants}
                      e
                      type="number"
                      {...formik.getFieldProps("nombreEnfants")}
                      required
                    />
                  </Col>

                  {/* Niveau d'étude Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="niveauEtude"
                      label="Niveau d'étude"
                      {...formik.getFieldProps("niveauEtude")}
                      required
                    >
                      <option value="">Sélectionner</option>
                      <option value="PRIMAIRE">Primaire</option>
                      <option value="SECONDAIRE">Secondaire</option>
                      <option value="UNIVERSITAIRE">Universitaire</option>
                      <option value="AUCUN">Aucun</option>
                    </AvField>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-primary me-2" />
                  <h5 className="mb-0">{t("Localisation géographique")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* Campagne Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="campagneId"
                      label={t("Campagne")}
                      {...formik.getFieldProps("campagneId")}
                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.campagnes.map((campagne) => (
                        <option key={campagne.id} value={campagne.id}>
                          {campagne.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* District Dropdown - Read-only */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="districtId"
                      label={t("District")}
                      value={formik.values.districtId}
                      disabled
                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.districts.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Region Dropdown - Read-only */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="regionId"
                      label={t("Region")}
                      value={formik.values.regionId}
                      disabled
                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.regions.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Zone Dropdown - Read-only */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="zoneId"
                      label={t("Zone")}
                      value={formik.values.zoneId}
                      disabled
                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.zones.map((zone) => (
                        <option key={zone.id} value={zone.id}>
                          {zone.description}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Prefecture Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="prefectureId"
                      value={formik.values.prefectureId}
                      label={t("Préfecture")}
                      {...formik.getFieldProps("prefectureId")}
                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.perfectures.map((prefecture) => (
                        <option key={prefecture.id} value={prefecture.id}>
                          {prefecture.nomPrefecture}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Sous-Prefecture Dropdown */}
                  <Col md={3}>
                    <AvField
                      type="select"
                      name="sousPrefectureId"
                      value={formik.values.sousPrefectureId}
                      label={t("Sous-Préfecture")}
                      {...formik.getFieldProps("sousPrefectureId")}

                    >
                      <option value="">{t("Sélectionner")}</option>
                      {masterData.sousPerfectures.map((sous) => (
                        <option key={sous.id} value={sous.id}>
                          {sous.nomSousPrefecture}
                        </option>
                      ))}
                    </AvField>
                  </Col>

                  {/* Localité Dropdown */}
                  <Col md={3}>
                    <AvField
                      name="localite"
                      label={t("Localité")}
                      type="text"
                      {...formik.getFieldProps("localite")}
                    ></AvField>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-primary me-2" />
                  <h5 className="mb-0">{t("Détails Agricoles")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    {/* Cooperative Selection */}
                    <Col md={3}>
                      <AvField
                        type="select"
                        name="cooperativeId"
                        label={t("Cooperative")}
                        {...formik.getFieldProps("cooperativeId")}
                        required
                      >
                        <option value="">{t("Sélectionner")}</option>
                        {masterData.cooperatives.map((cooperative) => (
                          <option key={cooperative.id} value={cooperative.id}>
                            {cooperative.description}
                          </option>
                        ))}
                      </AvField>
                    </Col>

                    {/* Faitière Selection */}
                    <Col md={3}>
                      <AvField
                        type="select"
                        name="faitiereId"
                        label={t("Faitière")}
                        {...formik.getFieldProps("faitiereId")}
                        required
                      >
                        <option value="">{t("Sélectionner")}</option>
                        {masterData.faitieres.map((faitiere) => (
                          <option key={faitiere.id} value={faitiere.id}>
                            {faitiere.description}
                          </option>
                        ))}
                      </AvField>
                    </Col>

                    {/* Cultures Agricoles Multi-Select */}
                    <Col md={12}>
                      <Label>{t("Cultures Agricoles")}</Label>
                      <Select
                        isMulti
                        options={masterData.culturesAgricoles.map((culture) => ({
                          value: culture.id,
                          label: culture.nomCulture,
                        }))}
                        onChange={handleCulturesSelection}
                        placeholder={t("Sélectionner")}
                        value={formik.values.cultures.map((culture) => ({
                          value: culture.cultureAgricoleId,
                          label: culture.nomCulture,
                        }))}
                      />
                    </Col>
                  </Row>

                  {/* Dynamically Generated Fields for Selected Cultures */}
                  {formik.values.cultures?.map((culture, index) => (
                    <div key={index} className="mt-4">
                      <h5>{`Détails pour ${culture.nomCulture}`}</h5>
                      <Row>
                        <Col md={3}>
                          <AvField
                            name={`cultures[${index}].nombreParcelles`}
                            label={t("Nombre de Parcelles")}
                            type="number"
                            value={culture.nombreParcelles || 0}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `cultures[${index}].nombreParcelles`,
                                parseInt(e.target.value, 10) || 0
                              )
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <AvField
                            name={`cultures[${index}].surfaceEnHectare`}
                            label={t("Surface en Hectares")}
                            type="number"
                            step="0.01"
                            value={culture.surfaceEnHectare}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `cultures[${index}].surfaceEnHectare`,
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <AvField
                            name={`cultures[${index}].productionEstimeeKg`}
                            label={t("Production Estimée (Kg)")}
                            type="number"
                            step="0.01"
                            value={culture.productionEstimeeKg}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `cultures[${index}].productionEstimeeKg`,
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <AvField
                            name={`cultures[${index}].chiffreAffaireEstime`}
                            label={t("Chiffre d'Affaires Estimé")}
                            type="number"
                            step="0.01"
                            value={culture.chiffreAffaireEstime}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `cultures[${index}].chiffreAffaireEstime`,
                                parseFloat(e.target.value) || 0
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <FaBoxes className="text-primary me-2" />
                  <h5 className="mb-0">{t("Géolocalisation")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ width: "100vw", height: "75vh", margin: 0, padding: 0 }}>
                  {coordinates.latitude && coordinates.longitude ? (
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{
                        border: 0,
                        margin: 0,
                        padding: 0,
                        position: "absolute", // Ensures it covers the full screen
                        top: 0,
                        left: 0,
                      }}
                      src={`https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&hl=es;z=14&output=embed`}
                      allowFullScreen
                      title="Google Map"
                    ></iframe>
                  ) : (
                    <p style={{ textAlign: "center", marginTop: "50%" }}>
                      {t("Géolocalisation non disponible pour ce producteur.")}
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </Container>
      )}
    </React.Fragment>
  );
};

export default ProducteurForm;
