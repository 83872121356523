import axiosInstance from "./api_helper";

const userService = {
    getUsers: async (params) => {
      const response = await axiosInstance.get('/users', { params });
      return response.data;
    },
    createUser: async (userData) => {
      return await axiosInstance.post('/users', userData);
    },
    updateUser: async (userData) => {
      return await axiosInstance.put(`/users/${userData.id}`, userData);
    },
    deleteRec: async(id) => {
      return axiosInstance.delete(`/users/delete/${id}`);
    },

    search : async (query) => {
      console.log(query);
      return axiosInstance.post(`/users/search`, JSON.stringify(query));
    },
    
  };
  
  export default userService;