import React, { Component, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import 'bootstrap/dist/css/bootstrap.min.css';


// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/navs.scss";
//Fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import AppRoute from "./routes/route";
import ErrorPage from "./pages/Error";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Layout from "./layout";

// Activating fake backend
fakeBackend();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutConfirm: false, // State to control the modal visibility
      isNavigating: false, // Flag to control navigation until after logout confirmation
    };

    this.getLayout = this.getLayout.bind(this);
    this.preventBackButton = this.preventBackButton.bind(this);
    this.confirmLogout = this.confirmLogout.bind(this);
  }

  componentDidMount() {
    this.preventBackButton();
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.onpopstate = null;
  }

  // Prevent user from going back to login page
  preventBackButton() {
    // Push a new state to the browser's history stack to prevent going back
    window.history.pushState(null, document.title, window.location.href);
   
    // Listen for the back button event and show the confirmation modal
    window.onpopstate = () => {
      if (!this.state.isNavigating) {
        this.setState({ showLogoutConfirm: true });
      }
      window.history.pushState(null, document.title,  window.location.href);
    };
  }

  // Confirm logout and redirect the user to the login page
  confirmLogout(answer) {
    if (answer) {
      this.setState({ isNavigating: true }); // Set the flag to allow navigation after logout confirmation
      window.location.href = '/login'; // Redirect to login page
    } else {
      this.setState({ showLogoutConfirm: false }); // Close the modal if user cancels
    }
  }



  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    // const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Routes>
          {/* Public Routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {/* Protected Routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AppRoute>
                  <Layout>{route.component}</Layout>
                </AppRoute>
              }
              key={idx}
              exact={true}
            />
          ))}

          {/* Error Page */}
          <Route path="/error" element={<ErrorPage />} />

          {/* Catch-all Route for Undefined Paths */}
          <Route path="*" element={<Navigate to="/error?status=404&message=Page%20Not%20Found" />} />
        </Routes>
        <Modal
          isOpen={this.state.showLogoutConfirm} // Open the modal if true
          toggle={() => this.setState({ showLogoutConfirm: false })} // Close the modal
        >
          <ModalHeader toggle={() => this.setState({ showLogoutConfirm: false })}>
            Confirm Logout
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to log out?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.confirmLogout(true)}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => this.confirmLogout(false)}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
